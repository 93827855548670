<template>
  <el-popover :trigger="trigger" :placement="placement" :width="width">
    <div>
      <h3 class="popover-header text-capitalize" v-if="title"> {{ title }}</h3>
      <div class="popover-body" v-if="body">{{ body }}</div>
    </div>
    <div slot="reference">
      <slot name="reference">
        <base-button link icon>
          <octo-icon :icon="icon"/>
        </base-button>
      </slot>
    </div>
  </el-popover>
</template>

<script>
import OctoIcon from "../octo-icon/OctoIcon";
import {Popover} from "element-ui";

export default {
  name: "OctoPopover",
  components: {OctoIcon, [Popover.name]: Popover},
  props: {
    width: {
      type: String,
      default: '400'
    },
    icon: {
      type: String,
      default: 'document'
    },
    title: {
      type: String,
      default: ''
    },
    body: {
      type: String,
      default: ''
    },
    trigger: {
      type: String,
      default: 'click'
    },
    placement: {
      type: String,
      default: 'top'
    }
  }
}
</script>

<style scoped>

</style>
