import Lead from "./lead";
import User from "./user";
import dealExtraInfo from "./dealExtraInfo";

const Deal = {
  id: null,
  status: '',
  sub_status: '',
  text: '',
  amount: '',
  date_in: null,
  date_out: null,
  competence_date: null,
  weekly_frequency: null,
  individual_course: false,
  deal_details: [],
  recall_count: 0,
  classroom_info: null,
  dealDates: [],
  dealExtraInfo: _.cloneDeep(dealExtraInfo),
  accounting_status: null,
  lead: Lead,
  user: User,
  sponsor_id: null,
  media: [],
  payments: [],
  paymentNotes: [],
  courseNotes: [],
  notes: [],
  allNotes: [],
};

export default Deal
