const EmailTemplate = {
  id: null,
  name: null,
  reply_to: null,
  attachments: null,
  description: null,
  subject: null,
  target:null,
  template: null,
  email_labels: []
};

export default EmailTemplate
