<template>
  <div>
    {{ lessons && lessons.length > 0 ? completedLessonNumber + '/' + lessons.length : 0 }}
  </div>
</template>

<script>

export default {
  name: "LessonsContainer",
  components: {},
  props: {
    lessons: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    completedLessonNumber() {
      return this.$_.filter(this.lessons, lesson => lesson.status === 'completed').length;
    }
  }
}
</script>

<style scoped>

</style>
