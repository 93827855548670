const DealEmailLabel = {
  default: {
    value: 'default',
  },
  work: {
    value: 'work'
  },
  pec: {
    value: 'pec',
  },
  personal: {
    value: 'personal',
  },
  financial_holder: {
    value: 'financial_holder',
  },
  holder: {
    value: 'holder',
  },
  other: {
    value: 'other',
  },
}

export default DealEmailLabel
