const DealExtraInfo = {
  id: null,
  consulting_in_presence: false,
  withheld_fee: false,
  km_traveled: 0,
  enrollment_amount: 0,
  contract_amount: 0,
  payment_type: 'transfer',
  enrollment_payment_type: 'transfer',
  installment_plan: '',
  financing_status: '',
  debt_collection_status: '',
  confirmed_date: '',
  lawyer_date: '',
};

export default DealExtraInfo
