<template>
  <card class="mr-2 shadow" body-classes="standard-card-body">
    <div slot="header">
      <div class="d-flex align-items-center">
        <h4 class="card-title text-capitalize">{{ $t('page.addresses') }}</h4>
        <base-button
          v-if="editable"
          link
          icon
          size="sm"
          class="ml-auto text-capitalize"
          @click="openModal({}, -1)">
          <octo-icon icon="add"/>
        </base-button>
      </div>
    </div>
    <el-table :show-header="false" stripe :data="entityAddresses">

      <el-table-column align="left">
        <div slot-scope="{ row }">
          <label-theme-component class="small">{{ row.alias }}</label-theme-component>
          <div>{{ row.postal_code }} {{ row.locality }}</div>
        </div>
      </el-table-column>

      <el-table-column align="center" width="60">
        <div slot-scope="{ row }" class="small">
          <octo-icon v-if="row.default" icon="checked"/>
        </div>
      </el-table-column>

      <el-table-column align="right" width="120" v-if="editable">
        <div slot-scope="{ row, $index }" class="d-flex justify-content-end">
          <el-tooltip content="Settings"
                      :open-delay="300"
                      placement="top">
            <base-button
              class="mx-1"
              @click="openModal(row, $index)"
              link
              size="sm"
              icon
            >
              <octo-icon icon="edit"/>
            </base-button>
          </el-tooltip>

          <el-tooltip content="Settings"
                      :open-delay="300"
                      placement="top">
            <base-button
              class="mx-1"
              @click="deleteAddress($index)"
              type="danger"
              size="sm"
              link
              icon
            >
              <octo-icon icon="wrong"/>
            </base-button>
          </el-tooltip>
        </div>
      </el-table-column>
      <template slot="empty">
        {{ $t('datatable.no_result') }}
      </template>
    </el-table>
    <modal
      bodyClasses="px-2"
      centered
      :show.sync="showModal"
    >
      <h5 slot="header" class="modal-title text-uppercase">
        {{ currentAddressIndex !== -1 ? currentAddress.alias : $t('page.new_address') }}
      </h5>
      <octo-entity-address-form :address="currentAddress"
                                :key="octoEntityAddressFormKey"
                                ref="OctoEntityAddressForm"/>

      <template slot="footer">
        <base-button link @click="saveCurrentAddress">
          <span class="text-capitalize">{{ $t('common.save') }}</span>
        </base-button>
      </template>

    </modal>
  </card>
</template>

<script>
import {MessageBox, Table, TableColumn} from 'element-ui';
import BaseTable from "@/components/BaseTable";
import Address from "@/models/address";
import OctoEntityAddressForm from "./OctoEntityAddressForm";
import BaseSwitch from "../BaseSwitch";
import BaseRadio from "@/components/Inputs/BaseRadio";
import Modal from "@/components/Modal";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {endpoints} from "@/routes/endpoints";

export default {
  name: "OctoEntityAddresses",
  components: {
    LabelThemeComponent,
    OctoIcon,
    Modal,
    BaseRadio,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseSwitch,
    OctoEntityAddressForm,
    BaseTable
  },
  data() {
    return {
      entityAddresses: [],
      currentAddressIndex: null,
      currentAddress: Address,
      showModal: false,
      octoEntityAddressFormKey: 1
    }
  },
  props: {
    addresses: {
      type: Array,
      default: () => []
    },
    saveSelf: {
      type: Boolean,
      default: false
    },
    registryId: {
      default: null
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.entityAddresses = this.$_.cloneDeep(this.addresses);
  },
  methods: {
    getEntityAddresses: function () {
      return this.entityAddresses;
    },

    openModal(address, index) {
      this.currentAddressIndex = index;
      this.currentAddress = this.$_.isEmpty(address) ? this.$_.cloneDeep(Address) : address;
      this.showModal = true;
      this.octoEntityAddressFormKey++;
    },

    saveCurrentAddress: async function () {
      try {
        const address = await this.$refs.OctoEntityAddressForm.validate();

        if (address.default) {
          this.$_.each(this.entityAddresses, (item) => {
            this.$set(item, 'default', false);
          })
        }

        if (this.currentAddressIndex < 0) {
          this.entityAddresses.push(address);
        } else {
          this.$set(this.entityAddresses, this.currentAddressIndex, address);
        }

        this.showModal = false;

        if (!this.$_.isEqual(this.entityAddresses, this.addresses) && this.saveSelf) {
          this.saveAddresses();
        }
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.data_error')});
      }

    },

    deleteAddress: function (index) {
      MessageBox({
        title: this.$t('page.delete_confirm_title'),
        message: this.$t('page.delete_confirm_address'),
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.no'),
        showCancelButton: true,
        type: 'warning'
      })
        .then(() => {
          this.entityAddresses.splice(index, 1);
          if (this.saveSelf) {
            this.saveAddresses();
          }
        })
        .catch(() => {
        });
    },

    saveAddresses() {
      this.$fullLoading.show();

      this.$api.put(
        endpoints.REGISTRY_UPDATE_ADDRESSES.replace('{id}', this.registryId),
        this.entityAddresses
      )
        .then((resp) => {
          this.$emit('onSetAddresses', resp.data);
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    }
  }
}
</script>

<style scoped>

</style>
