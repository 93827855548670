const Course = {
  id: null,
  active: true,
  type: '',
  code: '',
  title: '',
  description: '',
  lesson_number: 0,
  minimum_participants: 0,
  maximum_participants: 0,
  enrollment_price: 0,
  price: 0,
  courseModules: [],
  courses: [],
  tutors: [],
  tutorIds: []
};

export default Course
