<template>
  <div class="container-fluid">
    <octo-loader v-if="isLoading" />
    <div class="row" v-if="timelines.length > 1">
      <div class="col-12 d-flex justify-content-end">
        <card class="card card-plain mb-1" body-classes="standard-card-body">
          <base-pagination class="mb-0" v-model="currentPage" :per-page="1" :total="timelines.length"/>
        </card>
      </div>
    </div>
    <div class="row" v-if="timelines.length === 0 && !isLoading">
      <div class="col-12">
        <no-data-component :label="$t('common.no_history')"/>
      </div>
    </div>
    <div class="row" v-if="timelines.length >= 1 && !isLoading">
      <div class="col-12">
        <time-line type="simple" class="wizard-timeline">
          <time-line-item v-if="selectedTimeline.lead" inverted badge-type="success" badge-icon="filter">
            <div slot="header" class="d-flex justify-content-between align-items-center text-body">
              <div class="d-flex align-items-center">
                <div :class="`badge badge-success`">
                  {{ $t('callcenter.lead') }}
                </div>
                <div class="ml-2 small">{{selectedTimeline.lead.id | udid}}</div>
              </div>
              <octo-popover :title="$t('fields.notes')" :body="selectedTimeline.lead.notes" placement="left"/>
            </div>
            <div slot="content">
              <registry-history-lead :lead="selectedTimeline.lead"/>
            </div>
          </time-line-item>
          <time-line-item v-if="selectedTimeline.deal" inverted badge-type="info" badge-icon="euro">
            <div slot="header" class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <div :class="`badge badge-info`">
                  {{ $t('page.deal') }}
                </div>
                <div class="ml-2 small">{{selectedTimeline.deal.id | udid}}</div>
              </div>
              <octo-popover :title="$t('fields.notes')" :body="selectedTimeline.deal.text" placement="left"/>
            </div>
            <div slot="content">
              <registry-history-deal :deal="selectedTimeline.deal"/>
            </div>
          </time-line-item>
          <time-line-item v-if="selectedTimeline.students && selectedTimeline.students.length"
                          inverted
                          badge-type="danger"
                          badge-icon="users">
            <div slot="header" class="d-flex justify-content-between align-items-center">
              <div :class="`badge badge-danger`">
                {{ $t('didactics.students') }}
              </div>
            </div>
            <div slot="content">
              <registry-history-students :students="selectedTimeline.students"/>
            </div>
          </time-line-item>
        </time-line>
      </div>
    </div>
  </div>
</template>

<script>
  import {endpoints} from "@/routes/endpoints";
  import {TimeLine, TimeLineItem} from 'src/components';
  import OctoIcon from "@/components/octo-icon/OctoIcon";
  import BasePagination from "@/components/BasePagination";
  import RegistryHistoryDeal from "./RegistryHistoryDeal";
  import RegistryHistoryLead from "./RegistryHistoryLead";
  import RegistryHistoryStudents from "./RegistryHistoryStudents";
  import BaseAlert from "@/components/BaseAlert";
  import OctoLoader from "@/components/octo-loader/OctoLoader";
  import NoDataComponent from "@/components/NoDataComponent";
  import {Popover} from "element-ui";
  import OctoPopover from "../../../components/octo-popover/OctoPopover";

  export default {
    name: "RegistryHistory",
    components: {
      OctoPopover,
      NoDataComponent,
      OctoLoader,
      BaseAlert,
      RegistryHistoryStudents,
      BasePagination,
      RegistryHistoryLead,
      RegistryHistoryDeal,
      OctoIcon,
      TimeLine,
      TimeLineItem,
      [Popover.name]: Popover
    },
    data() {
      return {
        timelines: [],
        currentPage: 1,
        isLoading: false
      }
    },
    props: {
      registryId: {
        default: null
      }
    },
    mounted() {
      if (!this.registryId) {
        return;
      }

      this.isLoading = true;

      this.$api.get(endpoints.REGISTRY_GET_TIMELINES.replace('{id}', this.registryId))
        .then((resp) => {
          this.isLoading = false
          this.timelines = resp.data;
          if (this.timelines.length > 1) {
            this.$emit('onMultipleRegistryHistories');
          }
        })
        .catch(() => {
          this.isLoading = false
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        })
    },
    computed: {
      selectedTimeline() {
        return this.timelines[this.currentPage - 1] || {};
      },
    }
  }
</script>

<style scoped lang="scss">
</style>
