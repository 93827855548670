<template>
  <div>
    <el-table stripe :data="lessons" class="border-top">
      <el-table-column
        :label="$t('datatable.date')"
        :width="150"
        align="center"
        fixed
      >
        <div slot-scope="{ row }">
          <label-theme-component class=" text-uppercase">{{ row.date | day('short') }}</label-theme-component>
          {{ row.date | date }}
        </div>
      </el-table-column>
      <el-table-column
        :label="$t('datatable.time')"
        :width="120"
        align="center"
      >
        <div slot-scope="{ row }">
          <small>{{ row.time_start | time }} - {{ row.time_end | time }}</small>
        </div>
      </el-table-column>
      <el-table-column
        min-width="250"
        :label="$t('datatable.code')"
        align="center"
      >
        <div slot-scope="{ row }">
          <div class="text-uppercase small">{{ row.code }}</div>
        </div>
      </el-table-column>
      <el-table-column
        :label="$t('datatable.status')"
        :width="150"
        align="center"
      >
        <div slot-scope="{ row }">
          <badge :type="row.status | lessonStatusColor" class="text-uppercase">
            {{ $t('didactics.lesson_status.' + row.status) }}

          </badge>
        </div>
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('fields.type')"
        :width="150"
      >
        <div slot-scope="{ row }">
          <div class="text-uppercase small">{{ $t('didactics.lesson_type.' + row.type) }}</div>
        </div>
      </el-table-column>
      <el-table-column
        :label="$t('datatable.tutor')"
        :width="180"
        align="center">
        <div slot-scope="{ row }">
          <div v-if="row.tutor_id" class="text-uppercase">
            {{ users[row.tutor_id] | optional('lastname') }}
          </div>
        </div>
      </el-table-column>
      <el-table-column
        :label="$t('didactics.present')"
        :width="100"
        align="center"
        fixed="right"
      >
        <div slot-scope="{ row }">
          <div v-if="row.hasOwnProperty('studentPresence')">
            {{row.studentPresence ? 'P' : 'A'}}
          </div>
        </div>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui";
import Badge from "@/components/Badge";
import {mapGetters} from "vuex";
import LabelThemeComponent from "../../../components/LabelThemeComponent";

export default {
  name: "StudentLessons",
  components: {
    LabelThemeComponent,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Badge,
  },
  data() {
    return {
      lessons: []
    }
  },
  props: {
    classroomLessons: {
      type: Array,
      default: () => []
    },
    studentLessons: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
    }),



  },
  mounted() {
    const localClassroomLessons = this.$_.keyBy(
      this.$_.map(this.classroomLessons, (lesson) => {
        const lessonMatch = this.$_.find(this.studentLessons, {id: lesson.id});


        if (lessonMatch) {
          lesson.studentPresence = lessonMatch.studentPresence;
          lesson.show = true;
        }else {
          lesson.show = lesson.status !== "completed"
        }

        return lesson;


      }) || [], 'id');

    const localStudentLessons = this.$_.keyBy(
      this.$_.map(this.studentLessons, (lesson) => {
        lesson.show = true;
        return lesson;
      }) || [], 'id');

    const lessons = this.$_.filter({
      ...localClassroomLessons,
      ...localStudentLessons,
    }, 'show');

    this.lessons = this.$_.orderBy(lessons,'date');
  },
  methods: {
    getWeekDayFromLessonDate(date) {
      return this.$t('common.' + this.$moment(date)?.format('ddd')?.toLowerCase() + '-full');
    }
  }
}
</script>

<style scoped>

</style>
