<template>
  <div class="OctoLoadingLocal" :style="{height : `${height}px`}">
    <div class='loader loader1'>
      <div><div><div><div></div></div></div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OctoLoader",
  props: {
    height: {
      type: Number,
      default: 180
    }
  }
}
</script>

<style scoped lang="scss">

</style>
