<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <octo-header-button :title="`${registry.name} ${registry.surname}`"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <card
          v-for="(classroom, index) in localClassrooms" v-bind:key="`classroom-${index}`"
          body-classes="standard-card-body"
          class="shadow mb-3"
        >
          <div slot="header">
            <div class="d-flex align-items-center">
              <h4 class="card-title text-capitalize">{{ classroom.code }}</h4>
              <div class="ml-auto">
                <base-button link size="sm" @click="attachClassroom(classroom.id)">
                  {{ $t('common.attach') }}
                </base-button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ul class="list-group list-group-flush">
                <list-group-item-component
                  :label="$t('fields.schedulation')"
                  :value="classroom.schedulation"
                />
                <list-group-item-component
                  :label="$t('fields.expected_start_date')"
                  :value="classroom.expected_start_date | date"
                />
                <list-group-item-component
                  :label="$t('fields.status')"
                >
                  <template slot="value">
                    <badge
                      v-if="classroom.status"
                      :type="classroom.status | classroomStatusColor" class="text-uppercase">
                      {{ $t('didactics.classroom_status.' + classroom.status) }}
                    </badge>
                  </template>
                </list-group-item-component>
              </ul>
            </div>
            <div class="col-md-6">
              <ul class="list-group list-group-flush">
                <list-group-item-component :label="$t('didactics.students')" :value="classroom.students_count || 0"/>
                <list-group-item-component :label="$t('fields.tutors')" :value="classroom.tutors_count || 0"/>
                <list-group-item-component :label="$t('fields.lessons')">
                  <template slot="value">
                    <label-theme-component>
                      <lessons-container :lessons="classroom.lessons"/>
                    </label-theme-component>
                  </template>
                </list-group-item-component>
              </ul>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import Registry from "@/models/registry";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import Badge from "@/components/Badge";
import {endpoints} from "@/routes/endpoints";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import LessonsContainer from "@/pages/Didactics/components/LessonsContainer";
import LabelThemeComponent from "../../../components/LabelThemeComponent";

export default {
  name: "StudentWaitingClassroomOnGoing",
  components: {
    LabelThemeComponent,
    LessonsContainer,
    OctoIcon,
    ListGroupItemComponent,
    OctoHeaderButton,
    Badge
  },
  data() {
    return {
      localClassrooms: [],
    }
  },
  watch: {
    student: {
      handler(val) {
        this.localClassrooms = this.$_.cloneDeep(this.student.availableOnGoingClassrooms)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      student: 'current/studentPending',
    }),

    registry() {
      return this.student?.registry || this.$_.cloneDeep(Registry);
    }
  },
  methods: {
    attachClassroom(classroomId) {
      this.$api.put(
        endpoints.STUDENT_WAITING_ATTACH_CLASSROOM
          .replace('{id}', this.student.id)
          .replace('{classroomId}', classroomId)
      )
        .then(() => {
          this.$fullLoading.hide();
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
          this.$router.push({name: 'students.archive.show', params: {id: this.student.id}})
        })
        .catch(() => {
          this.$fullLoading.hide();
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
    }
  }
}
</script>

<style scoped>

</style>
