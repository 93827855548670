<template>
  <base-alert :type="alertType" class="py-4">
    <div class="text-white text-center text-uppercase" v-if="student.status">
      {{ $t('didactics.student_status.' + student.status) }}
    </div>
  </base-alert>
</template>

<script>

import BaseAlert from "@/components/BaseAlert";
import Student from "@/models/student";

export default {
  name: "StudentStatusContainer",
  components: {BaseAlert},
  data() {
    return {}
  },
  props: {
    student: {
      type: Object,
      default: () => this.$_.cloneDeep(Student)
    },
  },
  computed: {
    alertType: function () {
      return this.student.status ? this.$options.filters.studentStatusColor(this.student.status) : 'info';
    }
  }
}
</script>

<style scoped>

</style>
