import Registry from "./registry";
import Course from "./course";
import Classroom from "@/models/classroom";

const Student = {
  id: null,
  status: '',
  first_contact: '',
  second_contact: '',
  registry: Registry,
  course: Course,
  mainClassroom: Classroom,
  accounting_approval: false,
  notes: [],
  studentSchedulePreferences: [],
  starting_preference: null,
  deleted_at: null
};

export default Student
