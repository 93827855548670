<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-center"
      v-if="entity.type === transitionsTypes.transition.value"
    >
      <div class="text-uppercase">
        {{ entity | optional('classroomFrom.code') }}
      </div>
      <octo-icon icon="right-arrow"/>
      <div class="text-uppercase">
        {{ entity | optional('classroomTo.code') }}
      </div>
    </div>

    <div
      class="d-flex justify-content-between align-items-center"
      v-if="entity.type === transitionsTypes.attachment.value || entity.type === transitionsTypes.guest_attachment.value"
    >
      <div>{{ $t('didactics.student_classroom_transition_types.' + entity.type) }}:</div>
      <div>{{ entity | optional('classroomTo.code') }}</div>
    </div>

    <div
      class="d-flex justify-content-between align-items-center"
      v-if="entity.type === transitionsTypes.detachment.value || entity.type === transitionsTypes.guest_detachment.value"
    >
      <div>{{ $t('didactics.student_classroom_transition_types.' + entity.type) }}:</div>
      <div>{{ entity | optional('classroomFrom.code') }}</div>
    </div>
  </div>
</template>

<script>

import OctoIcon from "@/components/octo-icon/OctoIcon";
import StudentClassroomTransition from "@/models/studentClassroomTransition";
import StudentEventLog from "@/models/studentEventLog";
import StudentClassroomTransitionTypes from "@/pages/Students/resources/studentClassroomTransitionTypes";

export default {
  name: "StudentHistoryClassroomTransition",
  components: {OctoIcon},
  data() {
    return {
      entity: this.$_.cloneDeep(StudentClassroomTransition),
      transitionsTypes: StudentClassroomTransitionTypes
    }
  },
  props: {
    studentEventLog: {
      type: Object,
      default: () => this.$_.cloneDeep(StudentEventLog)
    }
  },
  mounted() {
    this.entity = this.studentEventLog.studentEventLoggable
  },
}
</script>

<style scoped>

</style>
