const StudentStatuses = {

  attending: {
    value: 'attending',
  },
  unjustified_absence : {
    value: 'unjustified_absence',
  },
  to_block_for_withdrawal : {
    value: 'to_block_for_withdrawal',
  },
  blocked_for_withdrawal: {
    value: 'blocked_for_withdrawal',
  },
  request_stand_by : {
    value: 'request_stand_by',
  },
  stand_by : {
    value: 'stand_by',
  },
  request_retired : {
    value: 'request_retired',
  },
  retired : {
    value: 'retired',
  },
  to_block_for_non_payment : {
    value: 'to_block_for_non_payment',
  },
  to_unblock : {
    value: 'to_unblock',
  },
  completed : {
    value: 'completed',
  },
  pending : {
    value: 'pending',
  },
  joined : {
    value: 'joined',
  },
  deleted : {
    value: 'deleted',
  }
}

export default StudentStatuses
