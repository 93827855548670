<template>
  <div
    class="mb-2 d-flex justify-content-between align-items-center octo-header-button"
    :class="[`header-${headerType}`]"
  >
    <div>
      <h4 class="my-0 ml-3 header-title text-uppercase text-truncate">{{ title }}</h4>
    </div>
    <div class="d-flex justify-content-end">
      <div
        v-for="(button, index) in buttons" v-bind:key="index"
        :class="index !==  0 ? 'border-left' : ''"
      >
        <base-button
          link
          @click="$emit(button.onClick)"
          :type="button.type"
          :confirm-required="button.confirmRequired"
          :confirm-title="button.confirmTitle"
          :confirm-text="button.confirmText"
          :confirm-yes="button.confirmYes"
          :confirm-no="button.confirmNo"
          class="text-uppercase">
          {{ $t('common.' + button.label) }}
        </base-button>
      </div>

    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "OctoHeaderButton",
  props: {
    buttons: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
  },
  computed: {
    ...mapGetters({
      themeColor: 'auth/themeColor',
    }),
    headerType: function () {
      if (this.type) {
        return this.type;
      }
      switch (this.themeColor) {
        case 'primary':
          return 'primary';
        case 'vue':
          return 'success';
        case 'green':
          return 'success';
        case 'red':
          return 'danger';
        case 'orange':
          return 'warning';
        case 'blue':
          return 'info';
        default:
          return 'primary';
      }
    }
  },
}
</script>

<style scoped lang="scss">
.header-button {
  min-height: 15px;
}

</style>
