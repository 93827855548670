<template>
  <div>
    <card body-classes="standard-card-body" class="mb-0">
      <div slot="header">
        <div class="d-flex align-items-center">
          <h4 class="card-title text-capitalize">{{ $t('fields.notes') }}</h4>
          <base-button v-if="!readOnly" link icon size="sm" class="ml-auto text-capitalize" @click="openModal">
            <octo-icon icon="add"/>
          </base-button>
        </div>
      </div>
      <div v-if="localNotes.length > 0" class="overflow-auto" :style="`height: ${height}`">
        <div v-for="(note, index) in localNotes" v-bind:key="`note-${index}`">
          <card
              class="card card-plain mb-2"
              body-classes="standard-card-body"
              header-classes="py-0 px-1"
              footer-classes="py-1 px-2"
          >
            <div class="d-flex">
              <div class="flex-grow-1">
                {{ note.text }}
              </div>
              <div class="ml-auto">
                <base-button v-if="!readOnly" link icon size="sm" class="my-0" @click="openModal(note)">
                  <octo-icon icon="edit"/>
                </base-button>
              </div>

            </div>

            <template slot="footer">
              <div class="small text-uppercase border-top">
                <div class="text-muted">
                  <span
                      v-if="note.tags"
                      v-for="(tag,index) in note.tags" v-bind:key="`tag-${index}`"
                      class="mr-1"
                  >
                    #{{ $t('fields.tag_labels.' + tag) }}
                  </span>
                </div>
                <div>
                  <label-theme-component>{{ $t('common.created_by') }}:</label-theme-component>
                  {{ users[note.created_by] | optional('lastname') }}
                  {{ users[note.created_by] | optional('firstname') }}
                  [ {{ note.created_at | datetime }} ]
                </div>
                <div>
                  <label-theme-component>{{ $t('common.updated_by') }}:</label-theme-component>
                  {{ users[note.updated_by] | optional('lastname') }}
                  {{ users[note.updated_by] | optional('firstname') }}
                  [ {{ note.updated_at | datetime }} ]
                </div>

              </div>
            </template>
          </card>
        </div>
      </div>
      <div v-else>
        <no-data-component :label="$t('common.no_notes')"/>
      </div>
    </card>
    <modal bodyClasses="px-2" centered :show.sync="showModal">
      <template slot="header">
        <h5 class="modal-title">{{ selectedNote.id ? $t('common.edit_note') : $t('common.new_note') }}</h5>
      </template>
      <ul class="list-group list-group-flush" v-if="availableTags.length !== 0">
        <list-group-item-component :label="$t('fields.tags')" :label-width="100">
          <template slot="value">
            <base-input class="mb-0 flex-grow-1">
              <el-select
                  multiple
                  class="select-default text-uppercase"
                  :placeholder="$t('fields.choose')"
                  v-model="selectedNote.tags"
              >
                <el-option
                    v-for="option in availableTags"
                    class="select-default text-uppercase w-100"
                    :value="option"
                    :label="$t('fields.tag_labels.' + option)"
                    :key="option"
                >
                </el-option>
              </el-select>
            </base-input>
          </template>
        </list-group-item-component>
      </ul>
      <base-text-area v-model="selectedNote.text" :rows="8"/>
      <template slot="footer">
        <base-button link class="text-capitalize" @click="saveNote">
          {{ $t('common.save_note') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import OctoIcon from "@/components/octo-icon/OctoIcon";
import NoDataComponent from "@/components/NoDataComponent";
import Modal from "@/components/Modal";
import BaseTextArea from "@/components/Inputs/BaseTextArea";
import Note from "@/models/note";
import {endpoints} from "@/routes/endpoints";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {mapGetters} from "vuex";
import ListGroupItemComponent from "@/components/ListGroupItemComponent.vue";
import {Option, Select} from "element-ui";

export default {
  name: "OctoNotes",
  components: {
    ListGroupItemComponent,
    LabelThemeComponent,
    BaseTextArea,
    NoDataComponent,
    OctoIcon,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      localNotes: [],
      showModal: false,
      selectedNote: this.$_.cloneDeep(Note)
    }
  },
  props: {
    entity: {
      type: String,
      validator: value => {
        let acceptedValues = [
          'classroom',
          'student',
          'lesson',
          'deal',
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    height: {
      type: String,
      default: 'auto'
    },
    type: {
      type: String,
      validator: value => {
        let acceptedValues = ['default', 'payment', 'course'];
        return acceptedValues.indexOf(value) !== -1;
      },
      default: 'default'
    },
    availableTags: {
      type: Array,
      default: () => []
    },
    entityId: {
      type: Number,
      require: true
    },
    notes: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users'
    })
  },
  mounted() {
    this.localNotes = this.$_.cloneDeep(this.notes);

  },
  methods: {
    openModal: function (note) {
      this.showModal = true;
      this.selectedNote = this.$_.cloneDeep(note || Note)
    },

    saveNote: function () {
      if (this.selectedNote.id) {
        this.updateNote();
      } else {
        this.storeNote();
      }
    },

    storeNote: function () {
      this.$fullLoading.show();
      this.$api.post(endpoints.NOTE_STORE, {
        entity: this.entity,
        entity_id: this.entityId,
        text: this.selectedNote.text,
        type: this.type,
        tags: this.selectedNote.tags || []
      })
          .then((resp) => {
            this.localNotes.push(resp?.data?.data);
            this.showModal = false;
            this.$emit('onSavedNotes', this.localNotes);
          })
          .catch((e) => {
            this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
          })
          .finally(() => {
            this.$fullLoading.hide();
          })
    },

    updateNote: function () {
      this.$fullLoading.show();
      this.$api.put(endpoints.NOTE_UPDATE.replace('{id}', this.selectedNote.id), {
        text: this.selectedNote.text,
        tags: this.selectedNote.tags || []
      })
          .then((resp) => {
            const noteData = resp?.data?.data;

            this.$set(this.localNotes, this.$_.findIndex(this.localNotes, note => note.id === noteData.id), noteData);

            this.showModal = false;
            this.$emit('onSavedNotes', this.localNotes);
          })
          .catch((e) => {
            this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
          })
          .finally(() => {
            this.$fullLoading.hide();
          })
    }
  }
}
</script>

<style scoped>

</style>
