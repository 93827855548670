<template>
  <card body-classes="standard-card-body">
    <ul class="list-group list-group-flush">
      <list-group-item-component :label="$t('fields.accounting_status')">
        <template slot="value">
          <badge :type="dealAccountingStatuses[accountingStatus].color" class="text-uppercase" v-if="accountingStatus">
            {{ $t('deal.accounting_statuses.' + accountingStatus) }}
          </badge>
        </template>
      </list-group-item-component>
    </ul>
  </card>
</template>

<script>
import BaseAlert from "@/components/BaseAlert";
import Student from "@/models/student";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import Badge from "@/components/Badge";
import DealAccountingStatus from "@/pages/Deals/resources/dealAccountingStatus";

export default {
  name: "StudentAccountingStatusCard",
  components: {Badge, ListGroupItemComponent, BaseAlert},
  data() {
    return {
      dealAccountingStatuses: DealAccountingStatus
    }
  },
  props: {
    student: {
      type: Object,
      default: () => this.$_.cloneDeep(Student)
    },
  },
  computed: {
    accountingStatus() {
      return this.student?.deal?.accounting_status || '';
    }
  }
}
</script>

<style scoped>

</style>
