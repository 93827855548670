<template>
  <ul class="list-group list-group-flush">
    <list-group-item-component
      :label="$t('callcenter.sale')"
      :value="$options.filters.optional(deal, 'user.lastname') + ' ' +
              $options.filters.optional(deal, 'user.firstname')"
    />
    <list-group-item-component :label="$t('common.status')">
      <template v-slot:value="{valueClass}">
        <div class="d-flex flex-column align-items-end">
          <badge v-if="deal.status" :type="deal.status | dealStatusColor" class="text-uppercase">
            {{ $t('deal.status.' + deal.status) }}
          </badge>
          <div :class="valueClass" class="small" v-if="deal.sub_status">
            {{ $t('deal.status.' + deal.sub_status) }}
          </div>
        </div>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('fields.date')">
      <template v-slot:value="{valueClass}">
        <div class="d-flex align-items-center small">
          <div :class="valueClass">{{deal.date_in | date}}</div>
          <div v-if="deal.date_out" class="mx-2"> <octo-icon icon="right-arrow"/> </div>
          <div v-if="deal.date_out" :class="valueClass">{{deal.date_out | date}}</div>
        </div>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('fields.courses')" v-if="deal.deal_details && deal.deal_details.length > 0">
      <template v-slot:value="{valueClass}">
        <div class="d-flex flex-column">
          <div class="text-right mb-2" v-for="(detail, index) in deal.deal_details" v-bind:key="`course-${index}`">
            <div class="text-muted">Cod. {{detail.detailable.code}}</div>
            <div :class="valueClass" class="small">{{detail.detailable.title}}</div>
          </div>
        </div>
      </template>
    </list-group-item-component>
  </ul>
</template>

<script>
import Deal from "@/models/deal";
import Badge from "@/components/Badge";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";

export default {
  name: "RegistryHistoryDeal",
  components: {ListGroupItemComponent, OctoIcon, Badge},
  props: {
    deal: {
      type: Object,
      default: () => Deal
    }
  },
}
</script>

<style scoped>

</style>
