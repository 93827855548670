const Classroom = {
  id: null,
  course_id: null,
  code: '',
  schedulation: '',
  status: '',
  expected_start_date: '',
  lessons: [],
  students: []
};

export default Classroom
