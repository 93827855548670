<template>
  <ValidationObserver ref="registryForm">
    <ul class="list-group list-group-flush">
      <list-group-item-component
        :label-width="labelWidth"
        :label="$t('fields.firstname')"
      >
        <template slot="value">
          <ValidationProvider
            name="firstname"
            rules="required"
            v-slot="{ passed, failed, errors }"
            class="flex-grow-1"
          >
            <base-input
              v-model="registryLocal.name"
              type="text"
              class="mb-0"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>

      <list-group-item-component
        :label-width="labelWidth"
        :label="$t('fields.lastname')"
      >
        <template slot="value">
          <ValidationProvider
            name="lastname"
            rules="required"
            v-slot="{ passed, failed, errors }"
            class="flex-grow-1"
          >
            <base-input
              v-model="registryLocal.surname"
              type="text"
              class="mb-0"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>

      <list-group-item-component
        :label-width="labelWidth"
        :label="$t('fields.codfis')"
      >
        <template slot="value">
          <base-input
            v-model="registryLocal.codfis"
            type="text"
            maxlength="16"
            class="mb-0 flex-grow-1"
          />

        </template>
      </list-group-item-component>

      <list-group-item-component
        :label-width="labelWidth"
        :label="$t('fields.gender')"
      >
        <template slot="value">
          <base-input class="flex-grow-1 mb-0">
            <el-select
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="registryLocal.gender"
            >
              <el-option
                v-for="option in [
                  { value: 'M', label: $t('common.male')},
                  { value: 'F', label: $t('common.female')},
                ]"
                class="select-default text-uppercase"
                :value="option.value"
                :label="option.label"
                :key="option.value"
              >
              </el-option>
            </el-select>
          </base-input>
        </template>
      </list-group-item-component>

      <list-group-item-component :label="$t('fields.nationality')" :label-width="labelWidth">
        <template slot="value">
          <base-input class="flex-grow-1 mb-0">
            <el-select
              class="select-default text-uppercase"
              filterable
              :placeholder="$t('fields.type_to_search')"
              v-model="registryLocal.nationality"
            >
              <el-option
                v-for="(country, index) in countries"
                class="select-default text-uppercase"
                :value="country.sigla_alpha_2"
                :label="country.name_it"
                :key="`country-${index}`"
              >
              </el-option>
            </el-select>
          </base-input>
        </template>
      </list-group-item-component>

      <list-group-item-component :label="$t('fields.family')" :label-width="labelWidth">
        <template slot="value">
          <base-input class="flex-grow-1 mb-0">
            <el-select
              class="select-default text-uppercase"
              filterable
              :placeholder="$t('fields.type_to_search')"
              v-model="registryLocal.family"
            >
              <el-option
                v-for="(option, index) in familyOptions"
                class="select-default text-uppercase"
                :value="option"
                :label="$t('registry.family.' + option)"
                :key="`family-${index}`"
              >
              </el-option>
            </el-select>
          </base-input>
        </template>
      </list-group-item-component>

      <list-group-item-component
        :label-width="labelWidth"
        :label="$t('fields.birth_date')"
      >
        <template slot="value">
          <base-input class="mb-0 flex-grow-1">
            <el-date-picker
              type="date"
              value-format="yyyy-MM-dd"
              format="dd-MM-yyyy"
              :placeholder="$t('fields.choose')"
              v-model="registryLocal.birth_date"
            >
            </el-date-picker>
          </base-input>
        </template>
      </list-group-item-component>
    </ul>
  </ValidationObserver>
</template>

<script>
import {Select, Option, DatePicker} from 'element-ui';
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {mapGetters} from "vuex";
import Registry from "@/models/registry";

export default {
  name: "RegistryForm",
  components: {
    ListGroupItemComponent,
    [Option.name]: Option,
    [Select.name]: Select,
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      registryLocal: this.$_.cloneDeep(Registry),
      labelWidth: 140,
      familyOptions: ['s', 'sf', 'sd', 'f', 'ff']
    }
  },
  props: {
    registry: {
      type: Object,
      default: () => _.cloneDeep(Registry)
    }
  },
  mounted() {
      this.registryLocal = this.$_.cloneDeep(this.registry);
  },
  methods: {
    getRegistryData: function () {
      return new Promise((resolve, reject) => {
        this.$refs.registryForm.validate()
          .then((success) => {
            if (!success) {
              reject();
            } else {
              resolve(this.registryLocal);
            }
          })
          .catch((err) => {
            reject(err)
          });
      });
    }
  },
  computed: {
    ...mapGetters({
      countries: 'common/countries',
    }),
  }
}
</script>

<style scoped lang="scss">

</style>
