<template>
  <tabs vertical square tabNavWrapperClasses="col-2" tabContentClasses="col-10" class="row">
    <tab-pane>
      <template slot="label">
        <el-popover trigger="hover" placement="right">
          <div class="text-uppercase">{{ $t('callcenter.receivers') }}</div>
          <octo-icon slot="reference" icon="contact"/>
        </el-popover>
      </template>
      <octo-email-data-receivers
        :emails="emails"
        :email-to-send="emailToSendLocal"
        :key="`receivers-${emailDataKey}`"
      />
    </tab-pane>
    <tab-pane>
      <template slot="label">
        <el-popover trigger="hover" placement="right">
          <div class="text-uppercase">{{ $t('common.attachments') }}</div>
          <octo-icon slot="reference" icon="link"/>
        </el-popover>
      </template>
      <octo-email-data-attachments
        ref="octoEmailDataAttachments"
        :email-to-send="emailToSendLocal"
        :available-attachments="availableAttachments"
        :key="`attachments-${emailDataKey}`"
      />
    </tab-pane>
    <tab-pane>
      <template slot="label">
        <el-popover trigger="hover" placement="right">
          <div class="text-uppercase">{{ $t('fields.text') }}</div>
          <octo-icon slot="reference" icon="edit"/>
        </el-popover>
      </template>
      <octo-email-data-content :email-to-send="emailToSendLocal" :key="`content-${emailDataKey}`"/>
    </tab-pane>
  </tabs>
</template>

<script>
import {TabPane, Tabs} from "@/components";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import EmailToSend from "@/models/emailToSend";
import OctoEmailDataReceivers from "@/components/octo-email/OctoEmailDataReceivers";
import OctoEmailDataAttachments from "@/components/octo-email/OctoEmailDataAttachments";
import OctoEmailDataContent from "@/components/octo-email/OctoEmailDataContent";
import {mapGetters} from "vuex";

export default {
  name: "OctoEmailData",
  components: {
    OctoEmailDataContent,
    OctoEmailDataAttachments,
    OctoEmailDataReceivers,
    OctoIcon,
    TabPane,
    Tabs,
  },
  props: {
    emailToSend: {
      type: Object,
      default: () => this.$_.cloneDeep(EmailToSend)
    },
    emails: {
      type: Array,
      default: () => []
    },
    availableAttachments: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  data() {
    return {
      emailDataKey: 1,
      emailToSendLocal: this.$_.cloneDeep(EmailToSend),
    }
  },
  mounted() {
    this.emailToSendLocal = this.$_.cloneDeep(this.emailToSend);
    this.emailDataKey++;
  },
  methods: {
    getData() {
      const emailToSendData = this.$_.cloneDeep(this.emailToSendLocal);

      if (emailToSendData.bccToUser && this.currentUser?.defaultEmail?.email) {
        emailToSendData.bcc.push(this.currentUser?.defaultEmail?.email);
      }

      emailToSendData.attachments = this.$refs.octoEmailDataAttachments.getAttachments();

      return emailToSendData;
    },
  }
}
</script>

<style scoped>

</style>
