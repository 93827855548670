<template>
  <ul class="list-group list-group-flush">
    <list-group-item-component
      v-if="permissionService.isAllowed([
              permissionService.SALES_MANAGER_ROLE,
              permissionService.CALL_CENTER_OPERATOR_ROLE
      ])"
      :label="$t('datatable.operator')"
      :value="$options.filters.optional(lead, 'user.lastname') + ' ' +
              $options.filters.optional(lead, 'user.firstname')"
    />
    <list-group-item-component
      :label="$t('common.provider')"
      :value="lead | optional('provider.name')"
      v-if="permissionService.isAllowed([
        permissionService.CALL_CENTER_MANAGER_ROLE,
        permissionService.SALES_MANAGER_ROLE
      ])"
    />
    <list-group-item-component :label="$t('fields.date')">
      <template v-slot:value="{valueClass}">
        <div class="d-flex justify-content-end">
          <div class="small" :class="valueClass">{{ lead.date_in | date }}</div>
          <div v-if="lead.date_out" class="mx-2">
            <octo-icon icon="right-arrow"/>
          </div>
          <div v-if="lead.date_out" class="small" :class="valueClass">{{ lead.date_out | date }}</div>
        </div>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('common.status')">
      <template v-slot:value="{valueClass}">
        <div class="d-flex flex-column align-items-end">
          <badge :type="lead.status | leadStatusColor" class="text-uppercase">
            {{ $t('callcenter.status.' + lead.status) }}
          </badge>
          <div :class="valueClass" class="small" v-if="lead.sub_status">
            {{ $t('callcenter.status.' + lead.sub_status) }}
          </div>
        </div>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('fields.courses')" v-if="lead.courses && lead.courses.length > 0">
      <template v-slot:value="{valueClass}">
        <div class="d-flex flex-column">
          <div class="standard-value text-right mb-2"
               v-for="(course, index) in lead.courses"
               v-bind:key="`course-${index}`">
            <div class="text-muted">Cod. {{ course.code }}</div>
            <div :class="valueClass" class="small">{{ course.title }}</div>
          </div>
        </div>
      </template>
    </list-group-item-component>
  </ul>
</template>

<script>
  import Lead from "@/models/lead";
  import Badge from "@/components/Badge";
  import OctoIcon from "@/components/octo-icon/OctoIcon";
  import ListGroupItemComponent from "@/components/ListGroupItemComponent";
  import {permissionService} from "@/util/permission-service";

  export default {
    name: "RegistryHistoryLead",
    components: {ListGroupItemComponent, OctoIcon, Badge},
    data() {
      return {
        permissionService: permissionService
      }
    },
    props: {
      lead: {
        type: Object,
        default: () => Lead
      }
    },
  }
</script>

<style scoped>

</style>
