<template>
  <card body-classes="standard-card-body" class="mr-2 shadow">
    <div slot="header">
      <div class="d-flex align-items-center">
        <h4 class="card-title text-capitalize">{{ $t('page.phones') }}</h4>
        <base-button
          v-if="editable"
          link
          icon
          size="sm"
          class="ml-auto text-capitalize"
          @click="openModal({}, -1)">
          <octo-icon icon="add"/>
        </base-button>
      </div>
    </div>
    <el-table :show-header="false" stripe :data="entityPhones">
      <el-table-column align="left">
        <div slot-scope="{ row }">
          <label-theme-component class="small">{{ row.label }}</label-theme-component>
          <div>{{ row.phone }}</div>
        </div>
      </el-table-column>
      <el-table-column align="center" width="60">
        <div slot-scope="{ row }" class="small">
          <label-theme-component>
            <octo-icon v-if="row.default" icon="checked"/>
          </label-theme-component>
        </div>
      </el-table-column>
      <el-table-column align="right" width="120" v-if="editable">
        <div slot-scope="{ row, $index }" class="d-flex justify-content-end">
          <el-tooltip content="Edit"
                      :open-delay="300"
                      placement="top">
            <base-button
              class="mx-1"
              @click="openModal(row, $index)"
              link
              size="sm"
              icon
            >
              <octo-icon icon="edit"/>
            </base-button>
          </el-tooltip>

          <el-tooltip content="Delete"
                      :open-delay="300"
                      placement="top">
            <base-button
              class="mx-1"
              @click="deletePhone($index)"
              type="danger"
              link
              size="sm"
              icon
            >
              <octo-icon icon="wrong"/>
            </base-button>
          </el-tooltip>
        </div>
      </el-table-column>
      <template slot="empty">
        {{ $t('datatable.no_result') }}
      </template>
    </el-table>
    <modal bodyClasses="px-2" centered :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ currentPhoneIndex !== -1 ? currentPhone.phone : $t('page.new_phone') }}
      </h5>
      <octo-entity-phone-form :phone="currentPhone"
                              :key="octoEntityPhoneFormKey"
                              ref="OctoEntityPhoneForm"/>

      <template slot="footer">
        <base-button link @click="saveCurrentPhone">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
  </card>
</template>

<script>
import BaseTable from "@/components/BaseTable";
import Phone from "@/models/phone";
import BaseSwitch from "../BaseSwitch";
import OctoIcon from "../octo-icon/OctoIcon";
import Modal from "../Modal";
import {MessageBox, Table, TableColumn} from 'element-ui';
import OctoEntityPhoneForm from "./OctoEntityPhoneForm";
import LabelThemeComponent from "../LabelThemeComponent";
import {endpoints} from "@/routes/endpoints";

export default {
  name: "OctoEntityPhones",
  components: {
    LabelThemeComponent,
    OctoEntityPhoneForm,
    Modal,
    OctoIcon,
    BaseSwitch,
    BaseTable,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      entityPhones: [],
      currentPhone: Phone,
      currentPhoneIndex: 0,
      octoEntityPhoneFormKey: 0,
      showModal: false
    }
  },
  props: {
    phones: {
      type: Array,
      default: () => []
    },
    saveSelf: {
      type: Boolean,
      default: false
    },
    registryId: {
      default: null
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.entityPhones = this.$_.cloneDeep(this.phones);
  },
  methods: {
    getEntityPhones: function () {
      return this.entityPhones;
    },

    openModal(phone, index) {
      this.currentPhoneIndex = index;
      this.currentPhone = this.$_.isEmpty(phone) ? this.$_.cloneDeep(Phone) : phone;
      this.showModal = true;
      this.octoEntityPhoneFormKey++;
    },

    saveCurrentPhone: async function () {
      try {
        const phone = await this.$refs.OctoEntityPhoneForm.validate();

        if (phone.default) {
          this.$_.each(this.entityPhones, (item) => {
            this.$set(item, 'default', false);
          })
        }

        if (this.currentPhoneIndex < 0) {
          this.entityPhones.push(phone);
        } else {
          this.$set(this.entityPhones, this.currentPhoneIndex, phone);
        }

        this.showModal = false;

        if (!this.$_.isEqual(this.entityPhones, this.phones) && this.saveSelf) {
          this.savePhones();
        }
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.data_error')});
      }
    },

    deletePhone: function (index) {
      MessageBox({
        title: this.$t('page.delete_confirm_title'),
        message: this.$t('page.delete_confirm_phone'),
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.no'),
        showCancelButton: true,
        type: 'warning'
      })
        .then(() => {
          this.entityPhones.splice(index, 1);
          if (this.saveSelf) {
            this.savePhones();
          }
        })
        .catch(() => {
        });
    },

    savePhones() {
      this.$fullLoading.show();

      this.$api.put(endpoints.REGISTRY_UPDATE_PHONES.replace('{id}', this.registryId), this.entityPhones)
        .then((resp) => {
          this.$emit('onSetPhones', resp.data);
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },
  }
}
</script>

<style scoped>

</style>
