<template>
  <div class="d-flex justify-content-between align-items-center">
    <div class="text-uppercase" v-if="entity.status_from">
      {{ $t('didactics.student_status.' + entity.status_from) }}
    </div>
    <octo-icon icon="right-arrow"/>
    <div class="text-uppercase" v-if="entity.status_to">
      {{ $t('didactics.student_status.' + entity.status_to) }}
    </div>
  </div>
</template>

<script>

import OctoIcon from "@/components/octo-icon/OctoIcon";
import StudentStatusTransition from "@/models/studentStatusTransition";
import StudentEventLog from "@/models/studentEventLog";

export default {
  name: "StudentHistoryStatusTransition",
  components: {OctoIcon},
  data(){
    return{
      entity:this.$_.cloneDeep(StudentStatusTransition)
    }
  },
  props: {
    studentEventLog: {
      type: Object,
      default: () => this.$_.cloneDeep(StudentEventLog)
    }
  },
  mounted() {
    this.entity = this.studentEventLog.studentEventLoggable
  },
}
</script>

<style scoped>

</style>
