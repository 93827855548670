<template>
  <div>
    <div class="small">Email: {{entity.receiver}}</div>
    <div class="small text-muted" v-if="entity.cc && entity.cc.length > 0">
      cc: {{entity.cc.join(', ')}}
    </div>
    <div class="small text-muted text-uppercase" v-if="templateName">
      {{ $t('common.template') }}: {{ $t(`emails.templates.${templateName}`) }}
    </div>
    <div class="small text-muted">
      <span class="text-uppercase">{{ $t('callcenter.subject') }}</span>: {{ entity.subject }}
    </div>
  </div>
</template>

<script>
import StudentEventLog from "@/models/studentEventLog";
import ArchiveEmail from "@/models/archiveEmail";
import {mapGetters} from "vuex";

export default {
  name: "StudentHistoryArchiveEmail",
  data(){
    return {
      entity: this.$_.cloneDeep(ArchiveEmail)
    }
  },
  props: {
    studentEventLog: {
      type: Object,
      default: () => this.$_.cloneDeep(StudentEventLog)
    }
  },
  mounted() {
    this.entity = this.studentEventLog.studentEventLoggable
  },
  computed: {
    ...mapGetters({
      emailTemplates: 'common/emailTemplates'
    }),
    templateName() {
      return this.$_.find(this.emailTemplates, (item) => item.id === this.entity.email_template_id)?.name || null
    }
  }
}
</script>

<style scoped>

</style>
