const StudentClassroomTransitionTypes = {
  guest_detachment: {
    value: 'guest_detachment',
  },
  guest_attachment: {
    value: 'guest_attachment',
  },
  detachment: {
    value: 'detachment',
  },
  attachment: {
    value: 'attachment',
  },
  transition: {
    value: 'transition',
  }
}

export default StudentClassroomTransitionTypes
