<template>
  <div class="d-flex justify-content-between align-items-center">
    <div class="text-uppercase">
      {{ courseFrom.code }}
    </div>
    <octo-icon icon="right-arrow"/>
    <div class="text-uppercase">
      {{ courseTo.code }}
    </div>
  </div>
</template>

<script>

import OctoIcon from "@/components/octo-icon/OctoIcon";
import StudentCourseTransition from "@/models/studentCourseTransition";
import StudentEventLog from "@/models/studentEventLog";
import {mapGetters} from "vuex";
import Course from "@/models/course";

export default {
  name: "StudentHistoryCourseTransition",
  components: {OctoIcon},
  data() {
    return {
      entity: this.$_.cloneDeep(StudentCourseTransition)
    }
  },
  props: {
    studentEventLog: {
      type: Object,
      default: () => this.$_.cloneDeep(StudentEventLog)
    }
  },
  mounted() {
    this.entity = this.studentEventLog.studentEventLoggable
  },
  computed: {
    ...mapGetters({
      courses: 'common/allCourses'
    }),

    courseFrom() {
      return this.courses[this.entity?.course_from_id] || this.$_.cloneDeep(Course);
    },

    courseTo() {
      return this.courses[this.entity?.course_to_id] || this.$_.cloneDeep(Course);
    }
  }
}
</script>

<style scoped>

</style>
