<template>
  <modal :show="showModal" @close="$emit('onClose')" centered modalClasses="modal-lg">
    <h5 slot="header" class="modal-title text-uppercase">
      {{ $t('common.send_to') }}
    </h5>
    <div v-if="step === 1">
      <ul class="list-group list-group-flush">
        <li v-for="(emailTemplate, index) in emailTemplateList"
            v-bind:key="`emailTemplate-${index}`"
            class="standard-li"
            v-on:click="selectTemplate(emailTemplate)"
        >
          <octo-icon
            :class="selectedEmailTemplate.id === emailTemplate.id ? 'text-success' : 'text-muted'"
            class="emailIcon"
            icon="email"
          />
          <div class="flex-grow-1 ml-3">
            <div class="text-uppercase">
              <label-theme-component>{{ $t('emails.templates.' + emailTemplate.name) }}</label-theme-component>
            </div>
            <div class="small">{{ emailTemplate.description }}</div>
          </div>
          <octo-icon
            class="ml-auto"
            v-if="selectedEmailTemplate.id === emailTemplate.id"
            icon="checked"
          />
        </li>
      </ul>
    </div>
    <octo-email-data
      ref="octoEmailData"
      :email-to-send="emailToSend"
      :emails="emails"
      :available-attachments="availableAttachments"
      :key="emailDataKey"
      v-if="step === 2"
    />
    <template slot="footer">
      <base-button
        :loading="loadingEmailBody"
        v-if="step === 1"
        link :disabled="!selectedEmailTemplate.id || loadingEmailBody" class="text-uppercase" @click="goToStep2">
        {{ $t('common.next') }}
      </base-button>
      <base-button
        :loading="loadingEmailBody"
        v-if="step === 2"
        link :disabled="!selectedEmailTemplate.id" class="text-uppercase"
        @click="sendEmail"
      >
        {{ $t('common.send_email') }}
      </base-button>
    </template>
  </modal>
</template>

<script>
import {mapGetters} from "vuex";
import Modal from "@/components/Modal";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import EmailTemplate from "@/models/emailTemplate";
import {TabPane, Tabs} from "@/components";
import {endpoints} from "@/routes/endpoints";
import EmailToSend from "@/models/emailToSend";
import OctoEmailData from "@/components/octo-email/OctoEmailData";

export const EMAIL_TEMPLATE_NAME = {
  GENERIC: 'generic',
  SALE_APPOINTMENT: 'sale_appointment',
  DEAL_DATES: 'deal_dates',
  COURSE_START: 'course_start',
  COURSE_START_FIRST_INSTALLMENT: 'course_start_first_installment',
  INSTALLMENT_MEMORANDUM: 'installment_memorandum',
  INSTALLMENT_REMINDER: 'installment_reminder',
  LAST_INSTALLMENT_REMINDER: 'last_installment_reminder',
  PATH_BLOCK: 'path_block',
  NO_REGULAR_WITHDRAWAL: 'no_regular_withdrawal',
  NAME_ENROLLMENT_REMINDER: 'enrollment_reminder',
  NOTICE_TO_LAWYER: 'notice_to_lawyer',
  ENROLLMENT_FEE_MEMORANDUM: 'enrollment_fee_memorandum',
}

export const EMAIL_TARGET_TYPE = {
  REGISTRY: 'Registry',
  LEAD: 'Lead',
  DEAL: 'Deal',
  STUDENT: 'Student',
}

export default {
  name: "OctoEmail",
  components: {
    OctoEmailData,
    LabelThemeComponent,
    OctoIcon,
    ListGroupItemComponent,
    Modal,
    TabPane,
    Tabs
  },
  data() {
    return {
      step: 1,
      emailDataKey: 1,
      loadingEmailBody: false,
      selectedEmailTemplate: this.$_.cloneDeep(EmailTemplate),
      emailToSend: this.$_.cloneDeep(EmailToSend),
      availableAttachments: [],
    }
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    targetId: {
      type: Number,
      default: null
    },
    targetType: {
      type: String,
      default: null
    },
    allowedEmailTemplates: {
      type: Array,
      default: () => []
    },
    emails: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapGetters({
      emailTemplates: 'common/emailTemplates',
    }),
    emailTemplateList: function () {
      return this.$_.filter(this.emailTemplates, (item) => {
        return this.$_.indexOf(this.allowedEmailTemplates, item.name) !== -1;
      });
    }
  },

  mounted() {
    this.emailToSend = this.$_.cloneDeep(EmailToSend);
  },
  methods: {
    selectTemplate(template) {
      this.selectedEmailTemplate = this.$_.cloneDeep(template);
    },

    async goToStep2() {
      try {
        this.loadingEmailBody = true;
        const response = await this.$api.get(endpoints.EMAIL_GET_BODY_V2
          .replace('{id}', this.selectedEmailTemplate.id)
          .replace('{targetId}', this.targetId.toString())
        )

        this.emailToSend.body = response?.data?.body;
        this.emailToSend.subject = response?.data?.subject;
        this.emailToSend.reply_to = response?.data?.reply_to;
        this.availableAttachments = response?.data?.attachments;

        this.emailDataKey++;

        this.step = 2;
      } catch (e) {

      } finally {
        this.loadingEmailBody = false;
      }
    },

    async sendEmail() {
      try {
        this.loadingEmailBody = true;
        const data = await this.$refs.octoEmailData.getData();
        data.target_id = this.targetId;
        data.target_type = this.targetType;

        await this.$api.post(
          endpoints.EMAIL_SEND.replace('{emailTemplateId}', this.selectedEmailTemplate.id),
          data
        );

        this.$notify({type: 'success', message: this.$t('notifications.mail_success')});
        this.$emit('emailSent');
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.mail_error')});
      } finally {
        this.loadingEmailBody = false;

      }
    }
  }
}
</script>

<style scoped lang="scss">
.emailIcon {
  font-size: 2.5em;
}
</style>
