<template>
  <card class="mr-2 shadow" body-classes="standard-card-body">
    <div slot="header">
      <div class="d-flex align-items-center">
        <h4 class="card-title text-capitalize">{{ $t('page.emails') }}</h4>
        <base-button
          v-if="editable"
          link
          icon
          size="sm"
          class="ml-auto text-capitalize"
          @click="openModal({}, -1)">
          <octo-icon icon="add"/>
        </base-button>
      </div>
    </div>

    <el-table :show-header="false" stripe :data="entityEmails">
      <el-table-column align="left">
        <div slot-scope="{ row }">
          <label-theme-component class="small">{{ row.label }}</label-theme-component>
          <div>{{ row.email }}</div>
        </div>
      </el-table-column>
      <el-table-column align="center" width="60">
        <div slot-scope="{ row }" class="small">
          <octo-icon v-if="row.default" icon="checked"/>
        </div>
      </el-table-column>
      <el-table-column align="right" width="120" v-if="editable">
        <div slot-scope="{ row, $index }" class="d-flex justify-content-end">
          <el-tooltip content="Settings" :open-delay="300" placement="top">
            <base-button link icon class="mx-1" size="sm" @click="openModal(row, $index)">
              <octo-icon icon="edit"/>
            </base-button>
          </el-tooltip>
          <el-tooltip content="Settings" :open-delay="300" placement="top">
            <base-button icon link class="mx-1" type="danger" size="sm" @click="deleteEmail($index)">
              <octo-icon icon="wrong"/>
            </base-button>
          </el-tooltip>
        </div>
      </el-table-column>
      <template slot="empty">
        {{ $t('datatable.no_result') }}
      </template>
    </el-table>
    <modal bodyClasses="px-2" centered :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ currentEmailIndex !== -1 ? currentEmail.email : $t('page.new_email') }}
      </h5>
      <octo-entity-email-form :email="currentEmail"
                              :key="octoEntityEmailFormKey"
                              ref="OctoEntityEmailForm"/>

      <template slot="footer">
        <base-button link @click="saveCurrentEmail">
          <span class="text-uppercase">{{ $t('common.' + labelButton) }}</span>
        </base-button>
      </template>
    </modal>
  </card>
</template>

<script>
import {MessageBox, Table, TableColumn} from 'element-ui';
import BaseTable from "@/components/BaseTable";
import Email from "@/models/email";
import OctoEntityEmailForm from "./OctoEntityEmailForm";
import BaseSwitch from "../BaseSwitch";
import BaseRadio from "@/components/Inputs/BaseRadio";
import Modal from "@/components/Modal";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import LabelThemeComponent from "../LabelThemeComponent";
import {endpoints} from "@/routes/endpoints";

export default {
  name: "OctoEntityEmails",
  components: {
    LabelThemeComponent,
    OctoIcon,
    Modal,
    BaseRadio,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseSwitch,
    OctoEntityEmailForm,
    BaseTable
  },
  data() {
    return {
      entityEmails: [],
      currentEmailIndex: null,
      currentEmail: Email,
      showModal: false,
      octoEntityEmailFormKey: 1,
      labelButton: 'save'
    }
  },
  props: {
    emails: {
      type: Array,
      default: () => []
    },
    saveSelf: {
      type: Boolean,
      default: false
    },
    registryId: {
      default: null
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.entityEmails = this.$_.cloneDeep(this.emails);
  },
  methods: {
    getEntityEmails: function () {
      return this.entityEmails;
    },

    openModal(email, index) {
      this.currentEmailIndex = index;
      this.labelButton = this.currentEmailIndex === -1 ? 'add' : 'update'
      this.currentEmail = this.$_.isEmpty(email) ? this.$_.cloneDeep(Email) : email;
      this.showModal = true;
      this.octoEntityEmailFormKey++;
    },

    saveCurrentEmail: async function () {
      try {
        const email = await this.$refs.OctoEntityEmailForm.validate();
        if (email.default) {
          this.$_.each(this.entityEmails, (item) => {
            this.$set(item, 'default', false);
          })
        }

        if (this.currentEmailIndex < 0) {
          this.entityEmails.push(email);
        } else {
          this.$set(this.entityEmails, this.currentEmailIndex, email);
        }
        this.showModal = false;

        if (!this.$_.isEqual(this.entityEmails, this.emails) && this.saveSelf) {
          this.saveEmails();
        }
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.data_error')});
      }

    },

    deleteEmail: function (index) {
      MessageBox({
        title: this.$t('page.delete_confirm_title'),
        message: this.$t('page.delete_confirm_email'),
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.no'),
        showCancelButton: true,
        type: 'warning'
      })
        .then(() => {
          this.entityEmails.splice(index, 1);
          if (this.saveSelf) {
            this.saveEmails();
          }
        })
        .catch(() => {
        });
    },
    saveEmails() {
      this.$fullLoading.show();

      this.$api.put(endpoints.REGISTRY_UPDATE_EMAILS.replace('{id}', this.registryId), this.entityEmails)
        .then((resp) => {
          this.$emit('onSetEmails', resp.data);
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    }
  },
}
</script>

<style scoped>

</style>
