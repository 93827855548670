<template>
  <ValidationObserver ref="emailForm">
    <ul class="list-group list-group-flush">
      <list-group-item-component :label="$t('fields.label')" :label-width="labelWidth">
        <template slot="value">
          <validation-provider
            name="label"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              class="mb-0 flex-grow-1"
              :error="errors[0]"
              :class="[{ 'has-success-select': passed }, { 'has-danger-select': failed }]"
            >
              <el-select
                class="select-default text-uppercase"
                :placeholder="$t('fields.choose')"
                v-model="emailLocal.label"
              >
                <el-option
                  v-for="(label,index) in DealEmailLabel"
                  v-bind:key="`email-${index}`"
                  class="select-default text-uppercase"
                  :value="label.value"
                  :label="$t(`common.labels.${label.value}`)"
                />
              </el-select>
            </base-input>
          </validation-provider>
        </template>
      </list-group-item-component>
      <list-group-item-component :label="$t('fields.default')" :label-width="labelWidth">
        <template slot="value">
          <base-input class="flex-grow-1 mb-0">
            <el-select
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="emailLocal.default"
            >
              <el-option
                v-for="option in [
                  { value: true, label: $t('common.yes')},
                  { value: false, label: $t('common.no')},
                ]"
                class="select-default text-uppercase"
                :value="option.value"
                :label="option.label"
                :key="option.value"
              >
              </el-option>
            </el-select>
          </base-input>
        </template>
      </list-group-item-component>
      <list-group-item-component :label="$t('fields.email')" :label-width="labelWidth">
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="email"
            rules="required|email"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              required
              v-model="emailLocal.email"
              type="text"
              class="mb-0"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>
    </ul>
  </ValidationObserver>
</template>

<script>
import Email from "@/models/email";
import {Select, Option} from 'element-ui';
import ListGroupItemComponent from "../ListGroupItemComponent";
import DealEmailLabel from "@/pages/Deals/resources/dealEmailLabel";

export default {
  name: "OctoEntityEmailForm",
  computed: {
    DealEmailLabel() {
      return DealEmailLabel
    }
  },
  data() {
    return {
      emailLocal: '',
      labelWidth: 140
    }
  },
  components: {
    ListGroupItemComponent,
    [Option.name]: Option,
    [Select.name]: Select
  },
  props: {
    email: {
      type: Object,
      default: () => this.$_.cloneDeep(Email)
    }
  },
  mounted() {
    this.emailLocal = this.$_.cloneDeep(this.email);
  },
  methods: {
    validate() {

      return new Promise((resolve, reject) => {
        this.$refs.emailForm.validate()
          .then((success) => {
            if (!success) {
              reject();
            } else {
              resolve(this.emailLocal);
            }
          })
          .catch((err) => {
            reject(err);

          });
      });
    }
  },
}
</script>

<style scoped>

</style>
