var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"list-group list-group-flush"},[(_vm.permissionService.isAllowed([
            _vm.permissionService.SALES_MANAGER_ROLE,
            _vm.permissionService.CALL_CENTER_OPERATOR_ROLE
    ]))?_c('list-group-item-component',{attrs:{"label":_vm.$t('datatable.operator'),"value":_vm.$options.filters.optional(_vm.lead, 'user.lastname') + ' ' +
            _vm.$options.filters.optional(_vm.lead, 'user.firstname')}}):_vm._e(),(_vm.permissionService.isAllowed([
      _vm.permissionService.CALL_CENTER_MANAGER_ROLE,
      _vm.permissionService.SALES_MANAGER_ROLE
    ]))?_c('list-group-item-component',{attrs:{"label":_vm.$t('common.provider'),"value":_vm._f("optional")(_vm.lead,'provider.name')}}):_vm._e(),_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.date')},scopedSlots:_vm._u([{key:"value",fn:function(ref){
    var valueClass = ref.valueClass;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',{staticClass:"small",class:valueClass},[_vm._v(_vm._s(_vm._f("date")(_vm.lead.date_in)))]),(_vm.lead.date_out)?_c('div',{staticClass:"mx-2"},[_c('octo-icon',{attrs:{"icon":"right-arrow"}})],1):_vm._e(),(_vm.lead.date_out)?_c('div',{staticClass:"small",class:valueClass},[_vm._v(_vm._s(_vm._f("date")(_vm.lead.date_out)))]):_vm._e()])]}}])}),_c('list-group-item-component',{attrs:{"label":_vm.$t('common.status')},scopedSlots:_vm._u([{key:"value",fn:function(ref){
    var valueClass = ref.valueClass;
return [_c('div',{staticClass:"d-flex flex-column align-items-end"},[_c('badge',{staticClass:"text-uppercase",attrs:{"type":_vm._f("leadStatusColor")(_vm.lead.status)}},[_vm._v(" "+_vm._s(_vm.$t('callcenter.status.' + _vm.lead.status))+" ")]),(_vm.lead.sub_status)?_c('div',{staticClass:"small",class:valueClass},[_vm._v(" "+_vm._s(_vm.$t('callcenter.status.' + _vm.lead.sub_status))+" ")]):_vm._e()],1)]}}])}),(_vm.lead.courses && _vm.lead.courses.length > 0)?_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.courses')},scopedSlots:_vm._u([{key:"value",fn:function(ref){
    var valueClass = ref.valueClass;
return [_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.lead.courses),function(course,index){return _c('div',{key:("course-" + index),staticClass:"standard-value text-right mb-2"},[_c('div',{staticClass:"text-muted"},[_vm._v("Cod. "+_vm._s(course.code))]),_c('div',{staticClass:"small",class:valueClass},[_vm._v(_vm._s(course.title))])])}),0)]}}],null,false,499973673)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }