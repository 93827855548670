const EmailToSend = {
  body: null,
  subject: null,
  bccToUser: false,
  cc: [],
  bcc: [],
  attachments: [],
  custom_attachments: {}
};

export default EmailToSend
