var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"list-group list-group-flush"},[_c('list-group-item-component',{attrs:{"label":_vm.$t('callcenter.sale'),"value":_vm.$options.filters.optional(_vm.deal, 'user.lastname') + ' ' +
            _vm.$options.filters.optional(_vm.deal, 'user.firstname')}}),_c('list-group-item-component',{attrs:{"label":_vm.$t('common.status')},scopedSlots:_vm._u([{key:"value",fn:function(ref){
            var valueClass = ref.valueClass;
return [_c('div',{staticClass:"d-flex flex-column align-items-end"},[(_vm.deal.status)?_c('badge',{staticClass:"text-uppercase",attrs:{"type":_vm._f("dealStatusColor")(_vm.deal.status)}},[_vm._v(" "+_vm._s(_vm.$t('deal.status.' + _vm.deal.status))+" ")]):_vm._e(),(_vm.deal.sub_status)?_c('div',{staticClass:"small",class:valueClass},[_vm._v(" "+_vm._s(_vm.$t('deal.status.' + _vm.deal.sub_status))+" ")]):_vm._e()],1)]}}])}),_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.date')},scopedSlots:_vm._u([{key:"value",fn:function(ref){
            var valueClass = ref.valueClass;
return [_c('div',{staticClass:"d-flex align-items-center small"},[_c('div',{class:valueClass},[_vm._v(_vm._s(_vm._f("date")(_vm.deal.date_in)))]),(_vm.deal.date_out)?_c('div',{staticClass:"mx-2"},[_c('octo-icon',{attrs:{"icon":"right-arrow"}})],1):_vm._e(),(_vm.deal.date_out)?_c('div',{class:valueClass},[_vm._v(_vm._s(_vm._f("date")(_vm.deal.date_out)))]):_vm._e()])]}}])}),(_vm.deal.deal_details && _vm.deal.deal_details.length > 0)?_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.courses')},scopedSlots:_vm._u([{key:"value",fn:function(ref){
            var valueClass = ref.valueClass;
return [_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.deal.deal_details),function(detail,index){return _c('div',{key:("course-" + index),staticClass:"text-right mb-2"},[_c('div',{staticClass:"text-muted"},[_vm._v("Cod. "+_vm._s(detail.detailable.code))]),_c('div',{staticClass:"small",class:valueClass},[_vm._v(_vm._s(detail.detailable.title))])])}),0)]}}],null,false,610441639)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }