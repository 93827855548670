var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"mr-2 shadow",attrs:{"body-classes":"standard-card-body"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h4',{staticClass:"card-title text-capitalize"},[_vm._v(_vm._s(_vm.$t('page.phones')))]),(_vm.editable)?_c('base-button',{staticClass:"ml-auto text-capitalize",attrs:{"link":"","icon":"","size":"sm"},on:{"click":function($event){return _vm.openModal({}, -1)}}},[_c('octo-icon',{attrs:{"icon":"add"}})],1):_vm._e()],1)]),_c('el-table',{attrs:{"show-header":false,"stripe":"","data":_vm.entityPhones}},[_c('el-table-column',{attrs:{"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('label-theme-component',{staticClass:"small"},[_vm._v(_vm._s(row.label))]),_c('div',[_vm._v(_vm._s(row.phone))])],1)}}])}),_c('el-table-column',{attrs:{"align":"center","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"small"},[_c('label-theme-component',[(row.default)?_c('octo-icon',{attrs:{"icon":"checked"}}):_vm._e()],1)],1)}}])}),(_vm.editable)?_c('el-table-column',{attrs:{"align":"right","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return _c('div',{staticClass:"d-flex justify-content-end"},[_c('el-tooltip',{attrs:{"content":"Edit","open-delay":300,"placement":"top"}},[_c('base-button',{staticClass:"mx-1",attrs:{"link":"","size":"sm","icon":""},on:{"click":function($event){return _vm.openModal(row, $index)}}},[_c('octo-icon',{attrs:{"icon":"edit"}})],1)],1),_c('el-tooltip',{attrs:{"content":"Delete","open-delay":300,"placement":"top"}},[_c('base-button',{staticClass:"mx-1",attrs:{"type":"danger","link":"","size":"sm","icon":""},on:{"click":function($event){return _vm.deletePhone($index)}}},[_c('octo-icon',{attrs:{"icon":"wrong"}})],1)],1)],1)}}],null,false,3053882384)}):_vm._e(),_c('template',{slot:"empty"},[_vm._v(" "+_vm._s(_vm.$t('datatable.no_result'))+" ")])],2),_c('modal',{attrs:{"bodyClasses":"px-2","centered":"","show":_vm.showModal},on:{"update:show":function($event){_vm.showModal=$event}}},[_c('h5',{staticClass:"modal-title text-uppercase",attrs:{"slot":"header"},slot:"header"},[_vm._v(" "+_vm._s(_vm.currentPhoneIndex !== -1 ? _vm.currentPhone.phone : _vm.$t('page.new_phone'))+" ")]),_c('octo-entity-phone-form',{key:_vm.octoEntityPhoneFormKey,ref:"OctoEntityPhoneForm",attrs:{"phone":_vm.currentPhone}}),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"link":""},on:{"click":_vm.saveCurrentPhone}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }