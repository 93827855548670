<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <octo-header-button
          :title="`${registry.name} ${registry.surname}`"
          :buttons="headerButtons"
          @onSaveStudent="saveStudent"
          @onOpenOctoEmail="openOctoEmail"
          @onRemoveStudent="removeStudent"
          @onGoToClassroom="$router.push({name: 'didactics.classrooms.pending.show', params: {id: mainClassroom.id}})"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-7">
        <card body-classes="standard-card-body" class="shadow mb-3">
          <ul class="list-group list-group-flush">
            <list-group-item-component :label="$t('fields.date_in')" :value="localStudent.date_in | date"/>
            <list-group-item-component
              :label="$t('fields.weekly_frequency')"
              :value="student | optional('deal.weekly_frequency')"
            />
            <list-group-item-component
              :label="$t('fields.individual_course')"
              :value="$options.filters.optional(student, 'deal.individual_course') ? $t('common.yes') : $t('common.no')"
            />
            <list-group-item-component :label="$t('common.status')" :label-width="labelWidth">
              <template slot="value">
                <base-input class="mb-0 flex-grow-1">
                  <el-select
                    :disabled="!isTeachingManager"
                    class="select-default text-uppercase"
                    clearable
                    :placeholder="$t('fields.choose')"
                    v-model="newStatus"
                  >
                    <el-option
                      class="select-default text-uppercase"
                      v-for="(status, index) in studentStatuses"
                      :value="status.value"
                      :label="$t('didactics.student_status.' + status.value)"
                      :key="`status-${index}`"
                    />
                  </el-select>
                </base-input>
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.is_new')" :label-width="labelWidth">
              <template slot="value">
                <el-switch class="mr-2" v-model="localStudent.is_new"/>
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.first_contact')" :label-width="labelWidth">
              <template slot="value">
                <base-input class="flex-grow-1 mb-0">
                  <el-date-picker
                    type="date"
                    v-model="localStudent.first_contact"
                    value-format="yyyy-MM-dd"
                    format="dd/MM/yyyy"
                    :placeholder="$t('common.select_day')"
                    :picker-options="pickerOptions"
                  />
                </base-input>
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.second_contact')" :label-width="labelWidth">
              <template slot="value">
                <base-input class="flex-grow-1 mb-0">
                  <el-date-picker
                    type="date"
                    v-model="localStudent.second_contact"
                    value-format="yyyy-MM-dd"
                    format="dd/MM/yyyy"
                    :placeholder="$t('common.select_day')"
                    :picker-options="pickerOptions"
                  />
                </base-input>
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.starting_preference')" :label-width="labelWidth">
              <template slot="value">
                <base-input class="flex-grow-1 mb-0" v-model="localStudent.starting_preference"/>
              </template>
            </list-group-item-component>
            <list-group-item-component :label="$t('fields.preferred_location')" :label-width="labelWidth">
              <template slot="value">
                <base-input class="mb-0 flex-grow-1">
                  <el-select
                    clearable
                    class="select-default text-uppercase"
                    value-key="code"
                    :placeholder="$t('common.type_to_search')"
                    v-model="localStudent.location_id"
                  >
                    <el-option
                      class="select-default text-uppercase"
                      v-for="(location, index) in locations"
                      :value="location.id"
                      :label="location.alias"
                      :key="`location-${index}`"
                    />
                  </el-select>
                </base-input>
              </template>
            </list-group-item-component>
          </ul>
        </card>
        <card class="shadow" body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('fields.course') }}</h4>
          </div>
          <ul class="list-group list-group-flush">
            <list-group-item-component>
              <template slot="label">
                <div>
                  <label-theme-component>{{ course.code }}:</label-theme-component>
                  {{ course.title }}
                </div>
              </template>
              <template slot="value">
                <base-button link icon size="sm" class="my-0" v-if="showChangeCourse" @click="showModal = true;">
                  <octo-icon icon="edit"/>
                </base-button>
              </template>
            </list-group-item-component>
          </ul>
        </card>
        <card body-classes="standard-card-body" class="shadow" v-if="student.lessons && student.lessons.length > 0">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('fields.lessons') }}</h4>
          </div>
          <student-lessons
            :student-lessons="student.lessons"
            :key="`student-lessons-${studentDataKey}`"
          />
        </card>
        <octo-notes
          :key="`octo-notes-${studentDataKey}`"
          entity="student"
          :entity-id="student.id"
          :notes="student.notes"
        />
      </div>
      <div class="col-md-5">
        <student-accounting-status-card :student="student"/>
        <student-status-container :student="student"/>
        <student-history :student-id="student.id" :key="`student-history-${studentDataKey}`"/>
      </div>
    </div>
    <modal bodyClasses="p-1" :show.sync="showModal" centered modalClasses="modal-lg">
      <template slot="header">
        <h5 class="modal-title text-uppercase">{{ $t('common.change_course') }}</h5>
      </template>
      <list-selector-component exclusive :list="availableCourses" ref="listSelectorComponent">
        <template v-slot:default="slotProps">
          <div class="small text-truncate">
            <label-theme-component>{{ allCourses[slotProps.item.id] | optional('code') }}:</label-theme-component>
            {{ allCourses[slotProps.item.id] | optional('title') }}
          </div>
        </template>
      </list-selector-component>
      <template slot="footer">
        <base-button
          link
          confirm-required
          class="text-uppercase"
          :confirm-text="$t('confirm.change_student_course')"
          @click="changeCourse"
        >
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
    <octo-email
      :target-id="student.id"
      :target-type="emailTargetType.STUDENT"
      :emails="registry.emails"
      :allowed-email-templates="allowedEmailTemplates"
      :show-modal="showEmailModal"
      :key="`email-modal-${emailModalKey}`"
      @onClose="showEmailModal = false"
      @emailSent="studentDataKey++; showEmailModal = false"
    />
  </div>
</template>

<script>

import {mapGetters, mapMutations} from "vuex";
import Registry from "@/models/registry";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import StudentPending from "@/store/current/resources/studentPending";
import StudentStatusContainer from "@/pages/Students/components/StudentStatusContainer";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {DatePicker, Select, Option, Switch} from "element-ui";
import {endpoints} from "@/routes/endpoints";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import Course from "@/models/course";
import StudentHistory from "@/pages/Students/components/StudentHistory";
import OctoNotes from "@/components/OctoNotes";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Modal from "@/components/Modal";
import ListSelectorComponent from "@/components/ListSelectorComponent";
import studentStatuses from "@/pages/Students/resources/studentStatuses";
import {permissionService} from "@/util/permission-service";
import StudentLessons from "@/pages/Students/components/StudentLessons";
import OctoEmail, {EMAIL_TEMPLATE_NAME, EMAIL_TARGET_TYPE} from "@/components/octo-email/OctoEmail";
import StudentAccountingStatusCard from "@/pages/Students/components/StudentAccountingStatusCard";
import StudentStatuses from "@/pages/Students/resources/studentStatuses";

export default {
  name: "StudentWaitingRecap",
  components: {
    StudentAccountingStatusCard,
    OctoEmail,
    StudentLessons,
    ListSelectorComponent,
    Modal,
    OctoIcon,
    OctoNotes,
    StudentHistory,
    LabelThemeComponent,
    ListGroupItemComponent,
    StudentStatusContainer,
    OctoHeaderButton,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
  },
  data() {
    return {
      localStudent: this.$_.cloneDeep(StudentPending),
      studentDataKey: 1,
      labelWidth: 180,
      showModal: false,
      newStatus: '',
      studentStatuses: StudentStatuses,
      emailModalKey: 1,
      showEmailModal: false,
      allowedEmailTemplates: [
        EMAIL_TEMPLATE_NAME.COURSE_START,
        EMAIL_TEMPLATE_NAME.COURSE_START_FIRST_INSTALLMENT,
      ],
      emailTargetType: EMAIL_TARGET_TYPE,
    }
  },
  watch: {
    student: {
      handler(val) {
        this.localStudent = this.$_.cloneDeep(this.student);
        this.studentDataKey++;
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      student: 'current/studentPending',
      allCourses: 'common/allCourses',
      locations: 'common/locations',
    }),

    showChangeCourse() {
      return this.student.status === studentStatuses.pending.value
        && permissionService.isAllowed([permissionService.TEACHING_MANAGER_ROLE]);
    },

    registry() {
      return this.student?.registry || this.$_.cloneDeep(Registry);
    },

    course() {
      return this.allCourses?.[this.student?.course_id] || this.$_.cloneDeep(Course);
    },

    pickerOptions() {
      return {firstDayOfWeek: 1};
    },

    mainClassroom() {
      return this.$_.find(this.student.classrooms, classroom => classroom.student_classroom === 'main') || {};
    },

    headerButtons() {
      const buttons = [
        {
          label: 'save',
          onClick: 'onSaveStudent'
        },
        {
          label: 'send_email',
          onClick: 'onOpenOctoEmail'
        }
      ];

      if (this.mainClassroom?.id) {
        buttons.push({
          label: 'go_to_classroom',
          onClick: 'onGoToClassroom'
        });
      }

      if (!this.student.deleted_at) {
        buttons.push({
          label: 'remove',
          onClick: 'onRemoveStudent',
          confirmRequired: true
        });
      }

      return buttons;
    },

    availableCourses() {
      return this.$_.filter(this.$_.cloneDeep(this.allCourses), course => course.type === this.course.type);
    },

    isTeachingManager() {
      return permissionService.isAllowed([permissionService.TEACHING_MANAGER_ROLE]);
    },
  },
  methods: {
    ...mapMutations({
      setStudentPendingRecapData: 'current/setStudentPendingRecapData',
      setPartialStudentPending: 'current/setPartialStudentPending'
    }),

    saveStudent() {
      this.$fullLoading.show();

      if (!this.$_.isEmpty(this.newStatus)) {
        this.localStudent.status = this.newStatus;
      }

      this.$api.put(endpoints.STUDENT_UPDATE_WAITING.replace('{id}', this.localStudent.id), this.localStudent)
        .then((resp) => {
          this.setStudentPendingRecapData(resp?.data?.data);
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },

    changeCourse: function () {
      this.$fullLoading.show();

      this.$api.put(
        endpoints.STUDENT_CHANGE_COURSE
          .replace('{id}', this.student.id)
          .replace('{courseId}', this.$refs.listSelectorComponent.getSelectedItem())
      )
        .then((resp) => {
          this.setPartialStudentPending({key: 'course_id', value: resp?.data?.data?.course_id});
          this.showModal = false;
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },

    openOctoEmail() {
      this.emailModalKey++;
      this.showEmailModal = true;
    },

    removeStudent(){
      this.$fullLoading.show();

      this.$api.delete(endpoints.STUDENT_DELETE.replace('{id}', this.localStudent.id))
        .then(() => {
          this.$notify({type: 'success', message: this.$t('notifications.deleted_success')});
          this.$router.push({name: 'students.waiting.index'});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.deleted_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    }
  }
}
</script>

<style scoped>

</style>
