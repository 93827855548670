<template>
  <div>
    <base-input
      class="flex-grow-1"
      v-model="emailToSend.subject"
      type="text"
      :label="$t('callcenter.subject')"
    />
    <octo-rich-text
      class="mt-3"
      :editor-height="350"
      :initial-value="emailToSend.body"
      @onEditorUpdate="(value) => this.emailToSend.body = value"
    />
  </div>
</template>

<script>
import OctoIcon from "@/components/octo-icon/OctoIcon";
import OctoRichText from "@/components/octo-rich-text/OctoRichText";
import EmailToSend from "@/models/emailToSend";

export default {
  name: "OctoEmailDataContent",
  components: {OctoRichText, OctoIcon},
  props: {
    emailToSend: {
      type: Object,
      default: () => this.$_.cloneDeep(EmailToSend)
    },
  },
}
</script>

<style scoped>

</style>
