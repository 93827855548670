<template>
  <div>
    <octo-loader v-if="isLoading"/>
    <base-alert type="info"
                class="text-center text-capitalize"
                v-if="studentEventLogs.length === 0 && !isLoading">
      {{ $t('common.no_history') }}
    </base-alert>
    <time-line type="simple"
               class="wizard-timeline"
               v-if="studentEventLogs.length && !isLoading">
      <time-line-item
        inverted
        :badge-type="studentHistoryComponentConst[event.loggable_type].color"
        :badge-icon="studentHistoryComponentConst[event.loggable_type].icon"
        v-for="(event, index) in eventsList"
        v-bind:key="`event-${index}`"
      >
        <div slot="header" class="d-flex justify-content-between align-items-center">
          <div :class="`badge badge-${studentHistoryComponentConst[event.loggable_type].color}`">
            {{ $t(studentHistoryComponentConst[event.loggable_type].titleLang) }}
          </div>
          <div>
            {{ event.created_at | date }} <span class="small text-muted">{{ event.created_at | time }}</span>
          </div>
        </div>
        <div slot="content">

          <div>
            <small>
              {{ $t('common.powered_by') }}
              {{ users[event.user_id] | optional('firstname') }}
              {{ users[event.user_id] | optional('lastname') }}
            </small>
          </div>

          <component :is="studentHistoryComponentConst[event.loggable_type].component"
                     :student-event-log="event"/>
        </div>
      </time-line-item>
    </time-line>

    <div class="row" v-if="eventsPages.length > 1">
      <div class="col-12 d-flex justify-content-end">
        <card class="card card-plain mb-1" body-classes="standard-card-body">
          <base-pagination class="mb-0" v-model="currentPage" :per-page=numberItemsPerPage :total="studentEventLogs.length"/>
        </card>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {TimeLine, TimeLineItem} from 'src/components';
import {endpoints} from "@/routes/endpoints";
import StudentHistoryComponentConst from "../resources/studentHistoryComponentConst";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import BaseAlert from "@/components/BaseAlert";
import BasePagination from "@/components/BasePagination";
import OctoLoader from "@/components/octo-loader/OctoLoader";

export default {
  name: "StudentHistory",
  components: {
    OctoLoader,
    BasePagination,
    BaseAlert,
    OctoIcon,
    TimeLine,
    TimeLineItem,
    StudentHistoryComponentConst
  },
  data() {
    return {
      studentHistoryComponentConst: StudentHistoryComponentConst,
      studentEventLogs: [],
      currentPage: 1,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users'
    }),
    eventsPages(){
      return this.$_.chunk(this.studentEventLogs, this.numberItemsPerPage);
    },
    eventsList() {
      return this.eventsPages[this.currentPage-1];
    }
  },
  props: {
    studentId:{
      default: null
    },
    numberItemsPerPage:{
      type: Number,
      default: 5
    }
  },
  mounted() {
    if (this.studentId) {
      this.isLoading = true;

      this.$api.get(endpoints.GET_STUDENT_EVENT_LOG.replace('{id}', this.studentId))
        .then((resp) => {
          this.studentEventLogs = resp.data;
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
}
</script>

<style scoped lang="scss">
</style>
