var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"emailForm"},[_c('ul',{staticClass:"list-group list-group-flush"},[_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.label'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('validation-provider',{attrs:{"name":"label","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0 flex-grow-1",class:[{ 'has-success-select': passed }, { 'has-danger-select': failed }],attrs:{"error":errors[0]}},[_c('el-select',{staticClass:"select-default text-uppercase",attrs:{"placeholder":_vm.$t('fields.choose')},model:{value:(_vm.emailLocal.label),callback:function ($$v) {_vm.$set(_vm.emailLocal, "label", $$v)},expression:"emailLocal.label"}},_vm._l((_vm.DealEmailLabel),function(label,index){return _c('el-option',{key:("email-" + index),staticClass:"select-default text-uppercase",attrs:{"value":label.value,"label":_vm.$t(("common.labels." + (label.value)))}})}),1)],1)]}}])})],1)],2),_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.default'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('base-input',{staticClass:"flex-grow-1 mb-0"},[_c('el-select',{staticClass:"select-default text-uppercase",attrs:{"placeholder":_vm.$t('fields.choose')},model:{value:(_vm.emailLocal.default),callback:function ($$v) {_vm.$set(_vm.emailLocal, "default", $$v)},expression:"emailLocal.default"}},_vm._l(([
                { value: true, label: _vm.$t('common.yes')},
                { value: false, label: _vm.$t('common.no')} ]),function(option){return _c('el-option',{key:option.value,staticClass:"select-default text-uppercase",attrs:{"value":option.value,"label":option.label}})}),1)],1)],1)],2),_c('list-group-item-component',{attrs:{"label":_vm.$t('fields.email'),"label-width":_vm.labelWidth}},[_c('template',{slot:"value"},[_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var passed = ref.passed;
              var failed = ref.failed;
              var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"text","error":errors[0]},model:{value:(_vm.emailLocal.email),callback:function ($$v) {_vm.$set(_vm.emailLocal, "email", $$v)},expression:"emailLocal.email"}})]}}])})],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }