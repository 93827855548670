<template>
  <div>
    <ul class="list-group list-group-flush mb-3" v-for="(student) in students" v-bind:key="student.id">
      <list-group-item-component
        :label="$t('fields.course')"
        :label-width="75"
        :value="courses[student.course_id] |optional('title')"/>
      <list-group-item-component :label="$t('fields.date')">
        <template v-slot:value="{valueClass}">
          <div class="d-flex justify-content-end">
            <div class="small" :class="valueClass">{{student.date_in | date}}</div>
            <div v-if="student.date_out" class="mx-2" ><octo-icon icon="right-arrow"/></div>
            <div v-if="student.date_out" class="small" :class="valueClass">{{student.date_out | date}}</div>
          </div>
        </template>
      </list-group-item-component>
      <list-group-item-component :label="$t('common.status')">
        <template slot="value">
          <badge :type="student.status | studentStatusColor" class="text-uppercase">
            {{ $t('didactics.student_status.' + student.status) }}
          </badge>
        </template>
      </list-group-item-component>
    </ul>
  </div>
</template>

<script>
  import Badge from "@/components/Badge";
  import OctoIcon from "@/components/octo-icon/OctoIcon";
  import ListGroupItemComponent from "@/components/ListGroupItemComponent";
  import {mapGetters} from "vuex";

  export default {
    name: "RegistryHistoryStudents",
    components: {ListGroupItemComponent, OctoIcon, Badge},
    props: {
      students: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      ...mapGetters({
        courses: 'common/allCourses'
      })
    }
  }
</script>

<style scoped>

</style>
