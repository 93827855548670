<template>
  <div class="d-flex flex-wrap align-items-center">
    <div class="mr-2 text-uppercase small">{{ title }}</div>
    <el-tag :key="tag" v-for="tag in tags" closable disable-transitions @close="handleClose(tag)">
      {{ tag }}
    </el-tag>
    <el-input
      class="input-new-tag"
      v-model="inputValue"
      v-if="inputVisible"
      ref="tagInput"
      size="mini"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
    />
    <el-button v-else class="button-new-tag text-capitalize" size="small" @click="showInput">
      <octo-icon icon="add" class="mx-1"/>
      {{ $t('common.add') }}
    </el-button>
  </div>
</template>

<script>
import {Tag, Button} from "element-ui";
import OctoIcon from "@/components/octo-icon/OctoIcon";

export default {
  name: "OctoTags",
  components: {
    OctoIcon,
    [Tag.name]: Tag,
    [Button.name]: Button,
  },
  data() {
    return {
      inputValue: '',
      inputVisible: false,
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    tags: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.tagInput.$refs.input.focus();
      });
    },

    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.tags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
  }
}
</script>

<style scoped>
el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
