const StudentEventLog = {
  id: null,
  student_id: null,
  user_id: null,
  loggable_type: null,
  loggable_id:null,
  studentEventLoggable: {},

};

export default StudentEventLog
