const StudentCourseTransition = {
  id: null,
  course_from_id: null,
  course_to_id: null,
  student_id: null,
  created_at: '',
  updated_at: '',
};

export default StudentCourseTransition
