const StudentClassroomTransition = {
  id: null,
  classroomFrom: null,
  classroomTo: null,
  student_id: null,
  created_at: '',
  updated_at: '',
};

export default StudentClassroomTransition
