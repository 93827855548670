<template>
  <ul class="list-group list-group-flush">
    <list-group-title-section-component :label="$t('common.preloaded_attachments')" v-if="availableAttachments"/>
    <li class="list-group-item" v-if="availableAttachments">
      <list-selector-component ref="listSelectorComponent" :number-items-per-page="2" :list="availableAttachments">
        <template v-slot:default="slotProps">
          <div class="standard-label text-truncate">
            {{ slotProps.item | optional('name') }}
          </div>
        </template>
      </list-selector-component>
    </li>
    <list-group-title-section-component :label="$t('common.attachments')"/>
    <li class="list-group-item">
      <el-upload
        auto-upload
        drag
        class="attachment-upload"
        ref="upload-attachment"
        :action="endpoints.CALL_CENTER_STORE_TEMP_ATTACHMENT"
        :file-list="fileList"
        :on-remove="removeAttachments"
        :on-success="updateAttachments"
        :http-request="httpRequest"
        multiple
      >
        <i class="el-icon-upload"/>
        <div class="el-upload__text">Drop attachment here or <em>click to upload</em></div>
      </el-upload>
    </li>
  </ul>
</template>

<script>
import {Upload} from "element-ui"
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import EmailToSend from "@/models/emailToSend";
import ListSelectorComponent from "@/components/ListSelectorComponent";
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";

export default {
  name: "OctoEmailDataAttachments",
  components: {
    ListGroupTitleSectionComponent,
    ListSelectorComponent,
    OctoIcon,
    [Upload.name]: Upload,
  },
  props: {
    emailToSend: {
      type: Object,
      default: () => this.$_.cloneDeep(EmailToSend)
    },
    availableAttachments: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      fileList: [],
      endpoints: endpoints
    }
  },
  methods: {
    async httpRequest(option) {

      const formData = new FormData();
      formData.append(option.filename, option.file, option.file.name);

      try {
        return await this.$api.post(option.action, formData)
      } catch (e) {
        console.log(e);
      }
    },

    updateAttachments(resp, file) {
      this.$set(
        this.emailToSend.custom_attachments,
        file.uid,
        file.response.data
      );
    },

    removeAttachments(file) {
      delete this.emailToSend.custom_attachments[file.uid];
    },

    getAttachments() {
      if (!this.availableAttachments && !this.availableAttachments?.length) {
        return [];
      }

      return this.$refs.listSelectorComponent.getSelectedItems();
    }
  }
}
</script>

<style scoped>

</style>
