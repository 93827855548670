import StudentHistoryStatusTransition from "@/pages/Students/components/StudentHistoryStatusTransition";
import StudentHistoryClassroomTransition from "@/pages/Students/components/StudentHistoryClassroomTransition";
import StudentHistoryCourseTransition from "@/pages/Students/components/StudentHistoryCourseTransition";
import StudentHistoryArchiveEmail from "@/pages/Students/components/StudentHistoryArchiveEmail";

const StudentHistoryComponentConst = {
  StudentStatusTransition: {
    titleLang: 'didactics.student_status_transition',
    color: 'success',
    component: StudentHistoryStatusTransition,
    icon: 'sync',
  },
  StudentClassroomTransition: {
    titleLang: 'didactics.student_classroom_transition',
    color: 'info',
    component: StudentHistoryClassroomTransition,
    icon: 'presentation',
  },
  StudentCourseTransition: {
    titleLang: 'didactics.student_course_transition',
    color: 'warning',
    component: StudentHistoryCourseTransition,
    icon: 'study',
  },
  EmailArchive: {
    titleLang: 'common.email_sent',
    component: StudentHistoryArchiveEmail,
    color: 'secondary',
    icon: 'email',
  },
}

export default StudentHistoryComponentConst
