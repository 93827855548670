<template>
  <ul class="list-group list-group-flush">
    <list-group-item-component :label="$t('fields.label')" :label-width="labelWidth">
      <template slot="value">

          <base-input
            class="mb-0 flex-grow-1"
          >
            <el-select
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="addressLocal.alias"
            >
              <el-option
                v-for="(label,index) in DealEmailLabel"
                v-bind:key="`email-${index}`"
                class="select-default text-uppercase"
                :value="label.value"
                :label="$t(`common.labels.${label.value}`)"
              />
            </el-select>
          </base-input>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('fields.default')" :label-width="labelWidth">
      <template slot="value">
        <base-input class="flex-grow-1 mb-0">
          <el-select
            class="select-default text-uppercase"
            :placeholder="$t('fields.choose')"
            v-model="addressLocal.default"
          >
            <el-option
              v-for="option in [
                  { value: true, label: $t('common.yes')},
                  { value: false, label: $t('common.no')},
                ]"
              class="select-default text-uppercase"
              :value="option.value"
              :label="option.label"
              :key="option.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('fields.country')" :label-width="labelWidth">
      <template slot="value">
        <base-input class="flex-grow-1 mb-0">
          <el-select
            class="select-default text-uppercase"
            filterable
            clearable
            :placeholder="$t('fields.type_to_search')"
            v-model="addressLocal.country"
          >
            <el-option
              v-for="(country, index) in countryOptions"
              class="select-default text-uppercase"
              :value="country.value"
              :label="country.label"
              :key="`country-${index}`"
            >
            </el-option>
          </el-select>
        </base-input>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('fields.administrative_area_level_1')" :label-width="labelWidth">
      <template slot="value">
        <base-input class="flex-grow-1 mb-0">
          <el-select
            class="select-default text-uppercase"
            filterable
            clearable
            :placeholder="$t('fields.type_to_search')"
            v-model="addressLocal.administrative_area_level_1"
          >
            <el-option
              v-for="(region, index) in regionOptions"
              class="select-default text-uppercase"
              :value="region.value"
              :label="region.label"
              :key="`region-${index}`"
            >
            </el-option>
          </el-select>
        </base-input>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('fields.administrative_area_level_2')" :label-width="labelWidth">
      <template slot="value">
        <base-input class="flex-grow-1 mb-0">
          <el-select
            class="select-default text-uppercase"
            filterable
            clearable
            :placeholder="$t('fields.type_to_search')"
            v-model="addressLocal.administrative_area_level_2"
          >
            <el-option
              v-for="(province, index) in provinceOptions"
              class="select-default text-uppercase"
              :value="province.value"
              :label="province.label"
              :key="`province-${index}`"
            >
            </el-option>
          </el-select>
        </base-input>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('fields.locality')" :label-width="labelWidth">
      <template slot="value">
        <base-input class="flex-grow-1 mb-0" type="text" v-model="addressLocal.locality"/>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('fields.postal_code')" :label-width="labelWidth">
      <template slot="value">
        <base-input class="flex-grow-1 mb-0" type="text" v-model="addressLocal.postal_code"/>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('fields.street_address')" :label-width="labelWidth">
      <template slot="value">
        <base-input class="flex-grow-1 mb-0" type="text" v-model="addressLocal.street_address"/>
      </template>
    </list-group-item-component>
  </ul>
</template>

<script>
  import { Select, Option } from 'element-ui';
  import Address from "@/models/address";
  import {mapGetters} from "vuex";
  import ListGroupItemComponent from "../ListGroupItemComponent";
  import DealEmailLabel from "@/pages/Deals/resources/dealEmailLabel";

  export default {
    name: "OctoEntityAddressForm",
    data() {
      return {
        addressLocal: Address,
        labelWidth: 140
      }
    },
    components: {
      ListGroupItemComponent,
      [Option.name]: Option,
      [Select.name]: Select
    },
    props: {
      address: {
        type: Object,
        default: () => _.cloneDeep(Address)
      }
    },
    mounted() {
      this.addressLocal = this.$_.cloneDeep(this.address);
    },
    methods: {
      validate() {
        return this.addressLocal
      },
    },
    computed: {
      DealEmailLabel() {
        return DealEmailLabel
      },
      ...mapGetters({
        countries: 'common/countries',
        regions: 'common/regions',
        provinces: 'common/provinces',
      }),

      countryOptions() {
        return this.$_.map(this.countries, country => {
          return {
            label: country.name_it,
            value: country.sigla_alpha_2,
          }
        });
      },
      regionOptions() {
        return this.$_.map(this.regions, region => {
          return {
            label: region.regione,
            value: region.regione,
          }
        });
      },
      provinceOptions() {
        return this.$_.map(this.provinces, province => {
          return {
            label: province.sovracomunale,
            value: province.sigla_automobilistica,
          }
        });
      },
    }
  }
</script>

<style scoped>

</style>
