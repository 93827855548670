<template>
  <card footer-classes="text-right" class="mr-2 shadow" body-classes="standard-card-body">
    <div slot="header">
      <div class="d-flex align-items-center">
        <h4 class="card-title text-capitalize">{{ $t('common.personal_data') }}</h4>
        <base-button
          v-if="editable"
          link
          icon
          size="sm"
          class="ml-auto text-capitalize"
          @click="openModal">
          <octo-icon icon="edit"/>
        </base-button>
      </div>
    </div>
    <ul class="list-group list-group-flush">
      <list-group-item-component
        :label="$t('fields.firstname')"
        :value="registryLocal.name"/>
      <list-group-item-component
        :label="$t('fields.lastname')"
        :value="registryLocal.surname"/>
      <list-group-item-component
        :label="$t('fields.codfis')"
        :value="registryLocal.codfis"/>
      <list-group-item-component
        :label="$t('fields.gender')"
        :value="registryLocal.gender"/>
      <list-group-item-component
        :label="$t('fields.birth_date')"
        :value="$options.filters.date(registryLocal.birth_date)"/>
      <list-group-item-component
        :label="$t('fields.nationality')"
        :value="countryName"/>
      <list-group-item-component
        :label="$t('fields.family')"
        :value="registryLocal.family"/>
    </ul>
    <modal bodyClasses="p-1" :show.sync="showModal" centered>
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('common.personal_data') }}
      </h5>
      <registry-form :registry="registryLocal" :key="registryFormKey" ref="registryForm"/>
      <template slot="footer">
        <base-button class="text-uppercase" link @click="updatePersonalData">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
  </card>
</template>

<script>
import BaseTable from "@/components/BaseTable";
import Modal from "@/components/Modal";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import RegistryForm from "../forms/RegistryForm";
import {endpoints} from "@/routes/endpoints";
import Registry from "@/models/registry";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {mapGetters} from "vuex";

export default {
  name: "RegistryPersonalData",
  components: {
    ListGroupItemComponent,
    OctoIcon,
    Modal,
    RegistryForm,
    BaseTable
  },
  data() {
    return {
      showModal: false,
      registryFormKey: 1,
      registryLocal: this.$_.cloneDeep(Registry),
    }
  },
  props: {
    registry: {
      type: Object,
      default: () => Registry
    },
    saveSelf: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      countries: 'common/countries'
    }),

    countryName() {
      return this.$_.find(this.countries, country => {
        return country.sigla_alpha_2 === this.registryLocal?.nationality
      })?.name_it || this.registry?.nationality;
    }
  },
  mounted() {
    this.registryLocal = this.$_.cloneDeep(this.registry);
  },
  methods: {
    getRegistryData: function () {
      return this.registryLocal;
    },

    openModal() {
      this.showModal = true;
      this.registryFormKey++;
    },

    updatePersonalData: async function () {
      if (this.saveSelf) {
        try {
          this.$fullLoading.show();

          const registryFormData = await this.$refs.registryForm.getRegistryData();

          const resp = await this.$api.put(
            endpoints.REGISTRY_UPDATE_PERSONAL_DATA.replace('{id}', this.registryLocal.id),
            registryFormData
          );

          this.registryLocal = resp?.data?.data;

          this.showModal = false;
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        } catch (e) {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        } finally {
          this.$fullLoading.hide();
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
