import Note from "@/models/note";

const StudentSchedulePreference = {
  id: null,
  day_of_week: null,
  morning: false,
  afternoon: false,
  late_afternoon: false,
  evening: false,
  note: _.cloneDeep(Note)
};

export default StudentSchedulePreference
