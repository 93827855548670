<template>
  <ul class="list-group list-group-flush">
    <list-group-item-component :label="$t('emails.receiver')" :label-width="100">
      <template slot="value">
        <base-input class="flex-grow-1">
          <el-select
            clearable
            class="select-default text-uppercase"
            :placeholder="$t('fields.choose')"
            v-model="emailToSend.to"
          >
            <el-option
              class="select-default text-uppercase d-flex justify-content-between"
              v-for="(email, index) in emails"
              v-bind:key="`email-${index}`"
              :value="email.email"
              :label="email.email"
            >
              <div>{{email.email}}</div>
              <div>{{email.label}}</div>

            </el-option>
          </el-select>
        </base-input>
      </template>
    </list-group-item-component>
    <li class="list-group-item">
      <octo-tags :tags="emailToSend.cc" :title="$t('common.cc')"/>
    </li>
    <li class="list-group-item">
      <octo-tags :tags="emailToSend.bcc" :title="$t('common.bcc')"/>
    </li>
    <li class="list-group-item standard-li">
      <div>
        <el-switch class="mr-2" v-model="emailToSend.bccToUser"/>
        {{ $t('common.bcc_to_current_user') }}
      </div>
    </li>
  </ul>
</template>

<script>
import {Select, Option, Switch} from "element-ui"
import OctoTags from "@/components/octo-tags/OctoTags";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import EmailToSend from "@/models/emailToSend";

export default {
  name: "OctoEmailDataReceivers",
  components: {
    ListGroupItemComponent,
    OctoTags,
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
  },
  props: {
    emails: {
      type: Array,
      default: () => []
    },
    emailToSend: {
      type: Object,
      default: () => this.$_.cloneDeep(EmailToSend)
    },
  },
}
</script>

<style scoped>

</style>
