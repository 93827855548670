const Note = {
  id: null,
  noteable_type: null,
  noteable_id: null,
  text: '',
  type: '',
  created_by: null,
  created_at: null,
  updated_by: null,
  updated_at: null,
  tags: []
};

export default Note
