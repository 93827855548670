const DealAccountingStatus = {
  active: {
    value: 'active',
    color: 'primary',
  },
  unsolved: {
    value: 'unsolved',
    color: 'danger'
  },
  paid: {
    value: 'paid',
    color: 'success',
  },
  excerpt_closure: {
    value: 'excerpt_closure',
    color: 'info',
  },
  withdrawal: {
    value: 'withdrawal',
    color: 'default',
  },
  contract_cancelled:{
    value: 'contract_cancelled',
    color: 'warning'
  },

  expected_funding_outcome: {
    value: 'expected_funding_outcome',
    color: 'primary',
  },
  credit_recovery: {
    value: 'credit_recovery',
    color: 'warning',
  }
}

export default DealAccountingStatus
