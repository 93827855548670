var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{staticClass:"border-top",attrs:{"stripe":"","data":_vm.lessons}},[_c('el-table-column',{attrs:{"label":_vm.$t('datatable.date'),"width":150,"align":"center","fixed":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('label-theme-component',{staticClass:" text-uppercase"},[_vm._v(_vm._s(_vm._f("day")(row.date,'short')))]),_vm._v(" "+_vm._s(_vm._f("date")(row.date))+" ")],1)}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('datatable.time'),"width":120,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('small',[_vm._v(_vm._s(_vm._f("time")(row.time_start))+" - "+_vm._s(_vm._f("time")(row.time_end)))])])}}])}),_c('el-table-column',{attrs:{"min-width":"250","label":_vm.$t('datatable.code'),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"text-uppercase small"},[_vm._v(_vm._s(row.code))])])}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('datatable.status'),"width":150,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('badge',{staticClass:"text-uppercase",attrs:{"type":_vm._f("lessonStatusColor")(row.status)}},[_vm._v(" "+_vm._s(_vm.$t('didactics.lesson_status.' + row.status))+" ")])],1)}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('fields.type'),"width":150},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"text-uppercase small"},[_vm._v(_vm._s(_vm.$t('didactics.lesson_type.' + row.type)))])])}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('datatable.tutor'),"width":180,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[(row.tutor_id)?_c('div',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm._f("optional")(_vm.users[row.tutor_id],'lastname'))+" ")]):_vm._e()])}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('didactics.present'),"width":100,"align":"center","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[(row.hasOwnProperty('studentPresence'))?_c('div',[_vm._v(" "+_vm._s(row.studentPresence ? 'P' : 'A')+" ")]):_vm._e()])}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }