const Address = {
  id: null,
  default: false,
  alias: '',
  place_id: null,
  street_address: '',
  route: '',
  political: '',
  country: '',
  administrative_area_level_1: '',
  administrative_area_level_2: '',
  administrative_area_level_3: '',
  administrative_area_level_4: '',
  administrative_area_level_5: '',
  colloquial_area: '',
  locality: '',
  sublocality: '',
  neighborhood: '',
  premise: '',
  subpremise: '',
  plus_code: '',
  postal_code: '',
  natural_feature: '',
  airport: '',
  park: '',
  point_of_interest: '',
  other: '',
};

export default Address
