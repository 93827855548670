<template>
  <div>
    <octo-header-button
        :title="`${registry.name} ${registry.surname}`"
        :buttons="headerButtons"
        @onSavePreferences="savePreferences"
    />
    <card class="shadow mb-3">
      <div v-for="(preference, index) in localPreferences" v-bind:key="`group-${index}`">
        <card class="card card-plain mb-0 border-bottom" body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize mb-0">
              {{ $t('didactics.availability') }} {{ daysOfWeek[preference.day_of_week] }}
            </h4>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ul class="list-group list-group-flush">
                <list-group-item-component :label="$t('didactics.time_slot.morning') ">
                  <template slot="value">
                    <el-switch v-model="preference.morning"/>
                  </template>
                </list-group-item-component>
                <list-group-item-component :label="$t('didactics.time_slot.afternoon') ">
                  <template slot="value">
                    <el-switch v-model="preference.afternoon"/>
                  </template>
                </list-group-item-component>
                <list-group-item-component :label="$t('didactics.time_slot.late_afternoon') ">
                  <template slot="value">
                    <el-switch v-model="preference.late_afternoon"/>
                  </template>
                </list-group-item-component>
                <list-group-item-component :label="$t('didactics.time_slot.evening') ">
                  <template slot="value">
                    <el-switch v-model="preference.evening"/>
                  </template>
                </list-group-item-component>
              </ul>
            </div>
            <div class="col-md-6">
              <base-text-area :label="$t('fields.notes')" v-model="preference.note.text" :rows="3"/>
            </div>
          </div>
        </card>
      </div>
    </card>
  </div>
</template>

<script>

import {Card, Switch} from "element-ui";
import StudentSchedulePreference from "@/models/studentSchedulePreference";
import BaseTextArea from "@/components/Inputs/BaseTextArea";
import {mapGetters} from "vuex";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import Registry from "@/models/registry";
import {endpoints} from "@/routes/endpoints";
import Note from "@/models/note";

export default {
  name: "StudentSchedulePreferencesTab",
  components: {
    OctoHeaderButton,
    ListGroupTitleSectionComponent,
    ListGroupItemComponent,
    BaseTextArea,
    [Card.name]: Card,
    [Switch.name]: Switch,
  },
  data() {
    return {
      localPreferences: [],
      headerButtons: [
        {
          label: 'save_preferences',
          onClick: 'onSavePreferences'
        },
      ],
      daysOfWeek: {
        1: this.$t('common.mon-full'),
        2: this.$t('common.tue-full'),
        3: this.$t('common.wed-full'),
        4: this.$t('common.thu-full'),
        5: this.$t('common.fri-full'),
        6: this.$t('common.sat-full'),
        7: this.$t('common.sun-full'),
      }
    }

  },
  props: {
    preferences: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    student: function (val) {
      const studentPreferences = this.$_.keyBy(this.student.studentSchedulePreferences, 'day_of_week');
      this.localPreferences = this.$_.map(this.daysOfWeek, (dayOfWeek, dayIndex) => {
        let preference = this.$_.cloneDeep(StudentSchedulePreference);
        preference.day_of_week = dayIndex;
        preference.student_id = this.student.id;
        if (studentPreferences.hasOwnProperty(dayIndex)) {
          preference = {...preference, ...this.$_.cloneDeep(studentPreferences[dayIndex])}
        }

        preference.note = preference.note || this.$_.cloneDeep(Note)

        return preference;
      })
    },
  },
  computed: {
    ...mapGetters({
      student: 'current/studentPending',
    }),

    registry() {
      return this.student?.registry || this.$_.cloneDeep(Registry);
    }
  },
  mounted() {

  },
  methods: {
    savePreferences: function () {
      this.$fullLoading.show();
      this.$api.put(
          endpoints.STUDENT_UPDATE_SCHEDULE_PREFERENCES.replace('{id}', this.student.id),
          {
            preferences: this.$_.filter(this.localPreferences, (preference) => {
              return preference.morning
                  || preference.afternoon
                  || preference.late_afternoon
                  || preference.evening
                  || preference.note.text;
            }) || []
          }
      )
          .then(() => {
            this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
          })
          .catch(() => {
            this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
            // this.$router.push({name: 'didactics.classrooms.pending.index'})
          })
          .finally(() => {
            this.$fullLoading.hide();
          })

    },
  }
}
</script>

<style scoped lang="scss">
.iconPreferences {
  font-size: 20px;
}
</style>
