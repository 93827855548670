<template>
  <div class="row">
    <div class="col-md-6">
      <registry-personal-data
        save-self
        :registry="registry"
        :editable="editable"
      />
      <octo-entity-addresses
        ref="octoEntityAddresses"
        save-self
        :addresses="registry.addresses"
        :registry-id="registry.id"
        :editable="editable"
      />
      <octo-entity-emails
        save-self
        :emails="registry.emails"
        :registry-id="registry.id"
        :editable="editable"
        v-on:onSetEmails="handleOnSetEmails"
      />
      <octo-entity-phones
        save-self
        :phones="registry.phones"
        :registry-id="registry.id"
        :editable="editable"
      />
    </div>
    <div class="col-md-6">
      <registry-history :registry-id="registry.id"/>
    </div>
  </div>
</template>

<script>
import RegistryPersonalData from "@/pages/Registries/components/RegistryPersonalData";
import OctoEntityAddresses from "@/components/octo-entity-addresses/OctoEntityAddresses";
import OctoEntityEmails from "@/components/octo-entity-emails/OctoEntityEmails";
import OctoEntityPhones from "@/components/octo-entity-phones/OctoEntityPhones";
import RegistryHistory from "@/pages/Registries/components/RegistryHistory";
import Registry from "@/models/registry";
import email from "@/models/email";

export default {
  name: "RegistryTabPane",
  components: {RegistryHistory, OctoEntityPhones, OctoEntityEmails, OctoEntityAddresses, RegistryPersonalData},
  props: {
    registry: {
      type: Object,
      default: () => this.$_.cloneDeep(Registry)
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleOnSetEmails(emails) {
      this.$emit('onSetEmails', emails)
    },
  },
}
</script>

<style scoped>

</style>
